import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";

import noNature from "../../assets/media/noNature.webp";
import globe from "../../assets/emojis/globe.webp";

function AboutPage() {
  const navigate = useNavigate();

  const socialLogin = () => {
    if (window.social_url) {
      window.location.href = window.social_url;
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <main className="bg-white min-h-screen">
        <Header />
        <section className="flex justify-center items-center">
          <div className="grid py-20 px-8 max-w-7xl">
            <div className="flex">
              <div>
                <p className="text-4xl sm:text-6xl">Who is</p>
                <p className="text-5xl sm:text-7xl font-serif mb-4">
                  behind this
                </p>
              </div>
              <span
                role="img"
                aria-label="emoji"
                className="text-8xl mt-8 -ml-8"
              >
                <img alt="Nature" src={globe} className="w-24" />
              </span>
            </div>

            <div className="grid md:grid-cols-3">
              <div></div>
              <p className="col-span-2 text-2xl sm:text-4xl font-serif ">
                We are a team of AI experts set to putting our skills to good
                use. We set out to create an algorithm that tells you how much
                Nature has influenced your photos. Our goal is to donate all
                proceeds to organisations that are desperately trying to save
                Nature.
              </p>
            </div>
            <p className="mt-10">
              Find out more about our projects on{" "}
              <a
                href="https://graidient.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:opacity-60"
              >
                Gradient.com
              </a>
            </p>
          </div>
        </section>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "tween", duration: 1 }}
          className="rounded-xl m-4 sm:m-10 overflow-hidden"
        >
          <img src={noNature} className="w-full" alt="No Nature found" />
        </motion.div>
        <div className="flex flex-col items-center justify-center">
          <p>See how much you owe Nature</p>
          <button
            onClick={socialLogin}
            className="bg-gray-900 text-2xl text-yellow-50 font-light py-2 px-4 rounded-full mt-6 hover:bg-yellow-50 hover:text-gray-900 transition-all"
          >
            Connect your account
          </button>
        </div>
      </main>

      <Footer />
    </>
  );
}
export default AboutPage;
