import { motion } from "framer-motion/dist/framer-motion";
import Privacy from "./Privacy";
import React from "react";

import icon from "../assets/media/icon.svg";
import loveNature from "../assets/media/loveNature.svg";

import friends1 from "../assets/friends/friends1.jpg";
import friends2 from "../assets/friends/friends2.jpg";
import friends3 from "../assets/friends/friends3.jpg";
import friends4 from "../assets/friends/friends4.jpg";
import friends5 from "../assets/friends/friends5.jpg";

const friends = [friends1, friends2, friends3, friends4, friends5];

export default function Footer() {
  return (
    <footer className="z-10 flex flex-col justify-center items-center py-20 overflow-hidden">
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 1, opacity: 1 }}
        viewport={{ once: true }}
        transition={{
          type: "spring",
          bounce: 2,
          mass: 4,
          duration: 1,
          delay: 0.2,
        }}
      >
        {/* <h3 className="text-7xl font-black text-center transform -rotate-3 mb-10">
      Love, Nature
    </h3> */}
        <img src={loveNature} className="w-full" />
        <p className="font-serif text-center mt-6">Mutual friends with</p>
        <p className="font-light text-center">
          Animals, Insects, Clouds, Trees and 246 others
        </p>
        <div className="flex items-center justify-center gap-2 my-2">
          {friends.map((friend) => (
            <img src={friend} className="w-12 h-12 rounded-full" key={friend} />
          ))}
        </div>
      </motion.div>
      <div className="flex flex-col items-center justify-center mt-16">
        <p className="text-center">©2023</p>
        <p className="text-center">
          <img src={icon} className="inline w-8 h-8 -mt-2" /> Partnership with{" "}
          <b>Nature</b>
        </p>
        <p className="text-gray-500 mt-6 text-xs text-center">
          A project built by{" "}
          <a
            href="http://lateralgroup.com/"
            className="hover:opacity-60"
            target="_blank"
            rel="noreferrer"
          >
            Lateral
          </a>{" "}
          
          &{" "}
          <a
            href="https://graidient.com"
            className="hover:opacity-60"
            target="_blank"
            rel="noreferrer"
          >
            grAIdient
          </a>
          <br />
          {"Designed with ❤️ by "}
          <a
            href="https://uncommon.design"
            className="hover:opacity-60"
            target="_blank"
            rel="noreferrer"
          >
            uncommon
          </a>
        </p>

        <Privacy />

        {/* <div className="mt-6 flex gap-4 text-xs text-gray-500">
          <a
            className="hover:text-gray-900 hover:underline transition-all"
            href="/about"
          >
            About
          </a>
          <a
            className="hover:text-gray-900 hover:underline transition-all"
            href="/behind-the-scenes"
          >
            Behind the Scenes
          </a>
          <a
            className="hover:text-gray-900 hover:underline transition-all"
            href="/press"
          >
            Press kit
          </a>
        </div> */}
      </div>
    </footer>
  );
}
