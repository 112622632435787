import React from "react";
import icon from "../assets/media/icon.svg";
import Menu from "./Menu";

export default function Header() {
  return (
    <Menu />
    // <section className="flex flex-col items-center justify-center p-6">
    //   <div className="text-2xl z-20 text-center font-sans font-light hover:opacity-75 transition-all">
    //     <a href="/" className="flex gap-1 -mb-3">
    //       <img alt="NatureIcon" src={icon} className="w-8 h-8" />
    //       <h1>
    //         Unpaid partnership <br />
    //         with <strong>Nature</strong>
    //       </h1>
    //     </a>
    //   </div>
    // </section>
  );
}
