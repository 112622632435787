import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import NoNature from "./pages/nonature/NoNature";
import InvoicePage from "./pages/invoice/Invoice";
import ThankyouPage from "./pages/thankyou/ThankYou";
import NGOPage from "./pages/ngos/NGOs";
import AboutPage from "./pages/about/About";
import BehingTheScenesPages from "./pages/behindTheScenes/BehindTheScenes";
import PressPage from "./pages/press/Press";

import WhyNaturePage from "./pages/whyNature/WhyNature";
import ContactPage from "./pages/contact/Contact";
import DonatePage from "./pages/donate/Donate";
import FAQPage from "./pages/faq/Faq";
import TermsPage from "./pages/terms/Terms";
import PrivacyPage from "./pages/privacy/Privacy";
import InstagraConnect from "./pages/instagram-connect/InstagramConnect";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Home />} />
        <Route path="invoice" element={<InvoicePage />} />
        <Route path="nonature" element={<NoNature />} />
        <Route path="instagram-connect" element={<InstagraConnect />} />
        <Route path="thankyou" element={<ThankyouPage />} />
        <Route path="ngos" element={<NGOPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="behind-the-scenes" element={<BehingTheScenesPages />} />
        <Route path="press" element={<PressPage />} />
        <Route path="why-nature" element={<WhyNaturePage />} />
        <Route path="donate" element={<DonatePage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="faq" element={<FAQPage />} />
        <Route path="terms" element={<TermsPage />} />
        <Route path="privacy" element={<PrivacyPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
