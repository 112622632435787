import React, { useState } from "react";
import { NGOarray } from "../mocks/ngos";

import whale from "../assets/emojis/whale.webp";
import tree from "../assets/emojis/tree.webp";
import mountain from "../assets/emojis/mountain.webp";
import globe from "../assets/emojis/globe.webp";

const filterCategory = [
  { value: "water", label: "Water", emoji: [whale] },
  { value: "land", label: "Land", emoji: [tree] },
  { value: "air", label: "Air", emoji: [mountain] },
  { value: "everything", label: "Everything", emoji: [globe] },
];

function importAll(r) {
  return r.keys().map(r);
}

const logos = importAll(
  require.context("../assets/organizations/", false, /\.(png|jpe?g|svg|webp)$/)
);

NGOarray.forEach((org, index) => {
  org.logo = logos[index];
});
console.log(NGOarray);

export default function NGOs({ limit }) {
  const [selectedFilter, setSelectedFilter] = useState("water");

  return (
    <section className="flex justify-center items-center">
      <div className="grid text-left py-20 px-8 max-w-7xl">
        <div className="grid w-full">
          <div>
            <p className="text-4xl sm:text-6xl font-light">
              Donate to a cause that{" "}
            </p>
            <div className="flex flex-wrap">
              <p className="text-5xl sm:text-7xl font-serif mb-4 mr-2">
                supports:
              </p>{" "}
              <div className="z-10 -ml-2 sm:mt-3">
                <select
                  id="category"
                  value={selectedFilter}
                  className="text-xl font-light mx-2 sm:mx-3 bg-gray-900 mt-1 min-w-full px-5 py-3 text-yellow-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  rounded-full cursor-pointer"
                  onChange={(e) => setSelectedFilter(e.target.value)}
                  onBlur={(e) => setSelectedFilter(e.target.value)}
                >
                  {filterCategory.map((filter) => (
                    <option
                      key={filter.value}
                      value={filter.value}
                      className=""
                    >
                      {filter.label}
                    </option>
                  ))}
                </select>
              </div>
              <span className="z-20 text-8xl -mt-8 sm:-mt-2">
                {filterCategory.map((filter) => {
                  if (filter.value.includes(selectedFilter)) {
                    return (
                      <span
                        role="img"
                        aria-label="emoji"
                        key={filter.value}
                        value={filter.value}
                      >
                        <img src={`${filter.emoji}`} className="w-24" />
                      </span>
                    );
                  }
                })}
              </span>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-6 mt-10">
          {NGOarray.map((org) => {
            if (org.category.includes(selectedFilter)) {
              return (
                <div
                  key={org.name}
                  className="flex flex-col justify-between p-6 border border-gray-200 rounded-xl hover:border-blue-200 transition-all"
                >
                  <div>
                    {org.logo && (
                      <img
                        src={org.logo}
                        alt={org.name}
                        className="h-12 mb-3"
                      />
                    )}
                    <h3 className="text-2xl font-sans mb-4">{org.name}</h3>
                    <p>{org.description}</p>
                  </div>
                  <button className="w-min bg-gray-900 text-white text-2xl font-light py-2 px-4 rounded-full mt-3 hover:bg-blue-200 hover:text-blue-900 transition-all">
                    <a
                      href={`${org.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Donate
                    </a>
                  </button>
                </div>
              );
            }
          }).slice(0, limit)}
        </div>
      </div>
    </section>
  );
}
