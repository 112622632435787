import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import whyNature from "../../assets/media/whyNature.webp";
import natureVideo from "../../assets/media/PartnershipWithNature.mp4";
import globe from "../../assets/emojis/globe.webp";

function WhyNaturePage() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const socialLogin = () => {
    if (window.social_url) {
      window.location.href = window.social_url;
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <main className="bg-white min-h-screen">
        <Header />
        <section className="flex justify-center items-center">
          <div className="grid py-20 px-8 max-w-7xl">
            <div className="grid md:grid-cols-3">
              <div className="flex ">
                <div className="z-10">
                  <p className="text-4xl sm:text-6xl font-sans">Why</p>
                  <p className="text-5xl sm:text-7xl font-serif mb-4">
                    Nature?
                  </p>
                </div>
                <span
                  role="img"
                  aria-label="emoji"
                  className="text-8xl mt-8 -ml-8 z-0"
                >
                  <img alt="Nature" src={globe} className="w-24" />
                </span>
              </div>
              <p className="col-span-2 text-2xl sm:text-4xl font-serif mb-5">
              Brands and companies often collaborate with influencers to
              promote their products or services, leveraging the influencer's
              credibility and reach to connect with potential customers.
              Influencer marketing has become a popular and effective way for
              businesses to reach their target audience in a more authentic and
              relatable manner compared to traditional advertising.
              </p>
              <br/>
              <p className="col-span-2 text-2xl sm:text-4xl font-serif mb-5">
              We think Mother Nature is the biggest influencer on the planet, but
              she’s often taken for granted.
              </p>
              <br/>
              <p className="col-span-2 text-2xl sm:text-4xl font-serif ">
              It is time to partner up with Nature and reward her for the wonderful
              photos and videos that brought you fame (and sometimes fortune)
              on social media. Nature is Health and Health is Wealth.
              </p>
            </div>
          </div>
        </section>

        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "tween", duration: 1 }}
          className="rounded-xl m-4 sm:m-10 overflow-hidden relative"
        >
          <video autoPlay loop muted playsInline controls>
            <source src={natureVideo} type="video/mp4" /> Your browser does not
            support HTML5 video.
          </video>
        </motion.div>

        <div className="flex flex-col h-full w-full items-center justify-end text-gray-900 mt-10">
          <p className="font-serif text-4xl sm:text-6xl p-6 text-center">
            See how much you owe Nature
          </p>
          <button
            onClick={socialLogin}
            className="text-yellow-50 text-2xl bg-gray-900 font-light py-2 px-4 rounded-full hover:bg-yellow-50 hover:text-gray-900 transition-all"
          >
            Get your Estimate
          </button>
        </div>

        {/* <div className="absolute top-0 right-0 w-full h-full bg-gradient-to-b from-transparent to-black opacity-50"></div>
          <div className="absolute top-0 right-0 w-full h-full">
            <div className="flex flex-col h-full w-full items-center justify-end text-yellow-50 mb-10">
              <p className="font-serif text-4xl sm:text-6xl p-6 text-center">
                See how much you owe Nature
              </p>
              <button
                onClick={socialLogin}
                className="text-gray-900 text-2xl bg-yellow-50 font-light py-2 px-4 rounded-full hover:text-yellow-50 hover:bg-gray-900 transition-all"
              >
                Get your Estimate
              </button>
            </div>
          </div> */}
      </main>

      <Footer />
    </>
  );
}
export default WhyNaturePage;
