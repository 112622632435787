import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";

import behindTheScenes from "../../assets/media/behindTheScenes.mp4";
import worker from "../../assets/emojis/worker.webp";

function BehingTheScenesPages() {
  const navigate = useNavigate();

  const socialLogin = () => {
    if (window.social_url) {
      window.location.href = window.social_url;
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <main className="bg-white min-h-screen">
        <Header />
        <section className="flex justify-center items-center">
          <div className="grid py-20 px-8 max-w-7xl w-full">
            <div className="grid md:grid-cols-3">
              <div className="flex flex-col mb-4">
                <div>
                  <p className="text-5xl sm:text-6xl font-light">AI Tech</p>
                  <p className="text-5xl sm:text-7xl font-serif mb-4">
                    that helps Nature
                  </p>
                </div>
                <span
                  role="img"
                  aria-label="emoji"
                  className="text-8xl ml-28 -mt-12 sm:mt-8 sm:-ml-6"
                >
                  <img alt="Worker" src={worker} className="w-24" />
                </span>
              </div>
              <p className="col-span-2 text-2xl sm:text-4xl font-serif ">
              AI is so en vogue these days. Let's put it to good use.
              < br/>
              < br/>
              Our tech team has trained an AI super-power to scan your photos
              and detect nature. No human is involved, the computer is doing all
              the magic and gets better with time.
              < br/>
              < br/>
              Once nature is detected we calculate a suggested donation bill for
              your Partnership with Nature.
              </p>
            </div>
            <p className="mt-10">
              Find out more about our projects on{" "}
              <a
                href="https://graidient.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:opacity-60"
              >
                graidient.com
              </a>
            </p>
          </div>
        </section>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "tween", duration: 1 }}
          className="rounded-xl m-4 sm:m-10 overflow-hidden relative flex justify-center"
        >
          {" "}
          <video autoPlay loop muted playsInline>
            <source src={behindTheScenes} type="video/mp4" /> Your browser does
            not support HTML5 video.
          </video>
        </motion.div>

        <div className="flex flex-col items-center justify-center">
          <p>See how much you owe Nature</p>
          <button
            onClick={socialLogin}
            className="bg-gray-900 text-2xl text-yellow-50 font-light py-2 px-4 rounded-full mt-6 hover:bg-yellow-50 hover:text-gray-900 transition-all"
          >
            Get your Estimate
          </button>
        </div>
      </main>

      <Footer />
    </>
  );
}
export default BehingTheScenesPages;
