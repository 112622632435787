export const NGOarray = [
  {
    name: "1% for the Planet",
    description:
      "A leading global nonprofit, building a movement of businesses and individuals delivering philanthropic support to environmental organizations working on the front lines for our planet.",
    link: "https://onepercentfortheplanet.org/",
    category: ["water", "everything"],
  },
  {
    name: "4ocean",
    description:
      "As both a public benefit corporation and Certified B Corp, we’re committed to ending the ocean plastic crisis.",
    link: "https://www.4ocean.com",
    category: ["water", "everything"],
  },
  {
    name: "5Gyres",
    description:
      "Empowering action against the global health crisis of plastic pollution through science, education, and advocacy.",
    link: "https://www.5gyres.org/",
    category: ["water", "everything"],
  },
  {
    name: "Arbor Day Foundation",
    description:
      "Together, we planted 100 million trees and inspired 5 million new tree planters through the Time for Trees initiative. What will we be able to accomplish next?",
    link: "https://www.arborday.org",
    category: ["land", "everything"],
  },
  {
    name: "Bahamas Plastic Movement",
    description:
      "BPM’s mission is to build a community of education and activism around plastic pollution.",
    link: "https://www.bahamasplasticmovement.org/",
    category: ["water", "everything"],
  },
  {
    name: "Big Blue Ocean Cleanup",
    description:
      "Big Blue Ocean Cleanup works to prevent, protect and to minimise disturbance from human development and climate change.",
    link: "https://www.bigblueoceancleanup.org",
    category: ["water", "everything"],
  },
  {
    name: "Blue Frontier",
    description:
      "Blue Frontier builds the solution-oriented citizen engagement needed to protect our ocean, coasts and the communities, both human and wild that depend on them.",
    link: "https://bluefront.org/",
    category: ["water", "everything"],
  },
  {
    name: "Clear Blue Sea",
    description: "Mission: Cleanse the Oceans of Plastic Pollution.",
    link: "https://www.clearbluesea.org/",
    category: ["water", "everything"],
  },
  {
    name: "Conservation International",
    description:
      "Conservation International combines science, policy and fieldwork to help communities, countries and societies protect the Earth’s ecosystems.",
    link: "www.conservation.org",
    category: ["air", "everything"],
  },
  {
    name: "Environmental Paper Network",
    description:
      "We share a common vision of a forest, pulp and paper industry that contributes to a healthy, just and sustainable future for all life on earth.",
    link: "https://environmentalpaper.org/",
    category: ["air", "everything"],
  },
  {
    name: "EuroNatur Germany",
    description:
      "The foundation’s nature and species conservation projects help preserve Europe’s nature in its beauty and diversity.",
    link: "https://www.euronatur.org/en/",
    category: ["land", "everything"],
  },
  {
    name: "Forest Trends",
    description:
      "Today, it’s more profitable to destroy forests than it is to sustain them. Forest Trends’ mission is to change that equation.",
    link: "https://www.forest-trends.org/",
    category: ["land", "everything"],
  },
  {
    name: "Global Forest Coalition",
    description:
      "Advocate for the conservation and restoration of forest ecosystems, through defending and promoting respect for the rights, territories, traditional knowledge and sustainable livelihoods of the Indigenous Peoples, local communities and women that co-exist with them.",
    link: "https://globalforestcoalition.org/",
    category: ["land", "everything"],
  },
  {
    name: "Greenpeace",
    description:
      "Ending deforestation is our best chance to conserve wildlife and defend the rights of forest communities.",
    link: "https://www.greenpeace.org/usa/forests/solutions-to-deforestation/",
    category: ["air", "everything"],
  },
  {
    name: "Hawaii Land Trust",
    description:
      "We work in three programmatic areas: ʻĀina Protection, Stewardship, and Connection.",
    link: "https://www.hilt.org/",
    category: ["land", "everything"],
  },
  {
    name: "Kiss the Ground",
    description:
      "Our mission is to awaken people to the possibilities of regeneration and inspire participation in this movement through media, communications, education, workshops, immersive programming, and advocacy.",
    link: "https://kisstheground.com/",
    category: ["land", "everything"],
  },
  {
    name: "Mr Trash Wheel",
    description: "1,760.69 TONS of trash and debris collected so far.",
    link: "https://www.mrtrashwheel.com",
    category: ["water", "everything"],
  },
  {
    name: "National Forest Foundation",
    description:
      "Chartered by Congress, the National Forest Foundation was created with a simple mission: bring people together to restore and enhance our National Forests and Grasslands.",
    link: "https://www.nationalforests.org",
    category: ["land", "everything"],
  },
  {
    name: "NRDC",
    description:
      "Since 1970 NRDC has worked to ensure the rights of all people to clean air, clean water, and healthy communities",
    link: "https://www.nrdc.org/",
    category: ["air", "everything"],
  },
  {
    name: "Ocean Conservancy",
    description:
      "Ocean Conservancy works with millions of volunteers of all ages, from all around the world, on our International Coastal Cleanup—something we’ve been doing for more than 30 years.",
    link: "https://oceanconservancy.org/",
    category: ["water", "everything"],
  },
  {
    name: "Oceana",
    description:
      "We are restoring the world’s wild fish populations to serve as a sustainable source of protein for people.",
    link: "https://oceana.org/",
    category: ["water", "everything"],
  },
  {
    name: "Oceanic Preservation Society",
    description:
      "OPS inspires, empowers, and connects a global community using high-impact films and visual storytelling to expose the most critical issues facing our planet.",
    link: "https://www.opsociety.org",
    category: ["water", "everything"],
  },
  {
    name: "One Tree Planted",
    description:
      "We want to make it simple for anyone to help the environment by planting trees.",
    link: "https://onetreeplanted.org",
    category: ["land", "everything"],
  },
  {
    name: "Rainforest Alliance",
    description:
      "The Rainforest Alliance includes farmers and scientists, Iindigenous communities and governments, and people like YOU. Together, we can protect our forests.",
    link: "https://www.rainforest-alliance.org/",
    category: ["land", "everything"],
  },
  {
    name: "Regeneration International",
    description:
      "Most of our work falls under one of three major program areas: education, network-building and policy work.",
    link: "https://regenerationinternational.org/why-regenerative-agriculture/",
    category: ["land", "everything"],
  },
  {
    name: "Sea Legacy",
    description:
      "At SeaLegacy we’re on a mission to create healthy and abundant oceans.",
    link: "https://www.sealegacy.org/",
    category: ["water", "everything"],
  },
  {
    name: "SeaSheperd",
    description:
      "Sea Shepherd Conservation Society is an international non-profit with a worldwide presence and a mission to protect all marine animals.",
    link: "https://seashepherd.org/",
    category: ["water", "everything"],
  },
  {
    name: "Seabin Project",
    description:
      "Provides practical and tangible solutions to reduce the plastics in our oceans. Amount captured each day: 3,612.8 kg.",
    link: "https://seabinproject.com",
    category: ["water", "everything"],
  },
  {
    name: "SurfRider Foundation",
    description:
      "Dedicated to the protection and enjoyment of the world’s ocean, waves and beaches, for all people, through a powerful activist network.",
    link: "https://www.surfrider.org/",
    category: ["water", "everything"],
  },
  {
    name: "Take 3",
    description:
      "Take 3 pieces of rubbish with you when you leave the beach, waterway or...anywhere and you have made a difference.",
    link: "https://www.take3.org/",
    category: ["water", "everything"],
  },
  {
    name: "The Land Institute",
    description:
      "When people, land, and community are as one, all three members prosper; when they relate not as members but as competing interests, all three are exploited.",
    link: "https://landinstitute.org/",
    category: ["land", "everything"],
  },
  {
    name: "The Nature Conservancy",
    description:
      "Our mission is to conserve the lands and waters on which all life depends. To achieve this, we must boldly address the biodiversity and climate crises over the next decade.",
    link: "https://www.nature.org/en-us/",
    category: ["land", "everything"],
  },
  {
    name: "The Ocean Cleanup (System 001)",
    description:
      "Developing and scaling technologies to rid the world’s oceans of plastic. Our aim is to put ourselves out of business once the oceans are clean.",
    link: "https://theoceancleanup.com",
    category: ["water", "everything"],
  },
  {
    name: "The Ocean Foundation",
    description:
      "As the only community foundation for the ocean, we’re dedicated to reversing the trend of destruction of ocean environments around the world.",
    link: "https://oceanfdn.org/",
    category: ["water", "everything"],
  },
  {
    name: "TREES FOR THE FUTURE",
    description:
      "An agro-forestry resource centre working with people to improve livelihoods and restore degraded lands to sustainable productivity through tree planting.",
    link: "www.plant-trees.org",
    category: ["land", "everything"],
  },
  {
    name: "Waterfront Partnership",
    description:
      "We’re a non-profit organization known for getting things done around the Waterfront. We oversee the Waterfront Management Authority (WMA), a business improvement district dedicated to improved maintenance, beautification, and visitor services for Baltimore’s signature asset—the Waterfront.",
    link: "https://waterfrontpartnership.org/",
    category: ["water", "everything"],
  },
  {
    name: "WeForest",
    description:
      "To conserve and restore the ecological integrity of forests and landscapes, engaging communities to implement and deliver lasting solutions for climate, nature and people.",
    link: "https://www.weforest.org/",
    category: ["land", "everything"],
  },
  {
    name: "Woods Hole Oceanographic Institute",
    description:
      "The world’s independent leader in ocean discovery, exploration, and education, working to understand and sustain one of humanity’s most precious common resources.",
    link: "https://www.whoi.edu/",
    category: ["water", "everything"],
  },
  {
    name: "WWF",
    description:
      "For 60 years, WWF has worked to help people and nature thrive. At every level, we collaborate with people around the world to develop and deliver innovative solutions that protect communities, wildlife, and the places in which they live.",
    link: "https://www.worldwildlife.org/",
    category: ["land", "everything"],
  },
];
