import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion/dist/framer-motion";
import CountUp from "react-countup";
import { useEffect, useState, useRef } from "react";
import { CheckCircleIcon, FlagIcon, PencilIcon } from "@heroicons/react/solid";
import React from "react";
import { getCookie } from "../../service/cookies";
import { mockPhotoArray } from "../../mocks/photos";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../../components/Toast";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";

import icon from "../../assets/media/icon.svg";

const VALUE_PER_IMAGE = 1;

const MAX_FETCH_ITERATIONS = 30;
const FETCH_TIMEOUT_MS = 750;

const MIN_PAYMENT_VALUE = 10;
const MAX_PAYMENT_VALUE = 2000;

const MAX_IMAGES_SHOWING = 25;
const MAX_IMAGES_SHOWING_INVOICE = 5;

function InvoicePage() {
  const [photoArray, setPhotoArray] = useState([]);
  const [timeoutCounter, setTimeoutCounter] = useState(0);
  const timerRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Fetch images from API or mock on first render  */
  useEffect(() => {
    if (window.results_url && window.job_id && window.csrf_token) {
      if (timeoutCounter < MAX_FETCH_ITERATIONS) {
        timerRef.current = setTimeout(
          () =>
            fetchPhotosTimeout(
              window.results_url,
              window.job_id,
              window.csrf_token
            ),
          FETCH_TIMEOUT_MS
        );
      } else {
        setIsLoading(false);
      }
    } else {
      setPhotoArray(mockPhotoArray);
      setIsLoading(false);
    }

    return () => clearTimeout(timerRef.current);
  }, [timeoutCounter]);

  /** Send params to checkout session */
  const goToCheckout = (donation, userHandle) => {
    const formData = new FormData();
    formData.append("donationAmount", donation * 100);
    formData.append("donationName", userHandle);

    fetch("/checkout-session/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: formData,
    }).catch((e) => {
      console.log(e);
    });
  };

  /** Fetch photos from the API */
  const fetchPhotos = (url, jobId, csrfToken) => {
    const formData = new FormData();
    formData.append("jobId", jobId);
    formData.append("csrfmiddlewaretoken", csrfToken);

    fetch(url, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.imageTaskResults) {
          const photos = parsePhotos(JSON.parse(data.imageTaskResults));
          setPhotoArray(photos);
        }

        /** If job is done, set counter to max, so that we stop fetching */
        if (data.jobResult) {
          const result = JSON.parse(data.jobResult);
          if (result[0] && result[0].job_status === 2) {
            setTimeoutCounter(MAX_FETCH_ITERATIONS);
          }
        }
      })
      .catch((e) => {
        /** @todo: show error toast */
        console.log(e);
        setIsLoading(false);
      });
  };

  /** Fetch photos every x millisecodns */
  const fetchPhotosTimeout = (url, jobId, csrfToken) => {
    fetchPhotos(url, jobId, csrfToken);
    setTimeoutCounter((prevState) => prevState + 1);
  };

  /** Map photo response to our desired format */
  const parsePhotos = (photos) => {
    return photos.map((photo) => ({
      id: photo.pk,
      src: photo.fields.media_url,
      tag:
        photo.fields.result && photo.fields.result.class_name
          ? photo.fields.result.class_name
          : "In Progress",
      flagged: false,
    }));
  };

  /** Every time the image array updates, update the donation value */
  useEffect(() => {
    setDonation(
      photoArray.filter((photo) => !photo.flagged).length * VALUE_PER_IMAGE
    );
  }, [photoArray]);

  /** Flag image by index */
  const flagImage = (index) => {
    const newPhotoArray = [...photoArray];
    newPhotoArray[index].flagged = !newPhotoArray[index].flagged;
    setPhotoArray(newPhotoArray);
  };

  const userHandle = window.username || "instaHandle";
  const jobId = window.job_id;
  /** @todo: make invoice number unique
   * a: either make a random number generator on the front-end
   * b: or get an invoice number from the back-end
   */
  const invoiceNo = jobId;
  let today = new Date();
  const date = today.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const [donation, setDonation] = useState(0);
  const updateDonation = (e) => {
    e.preventDefault();
    setShowEdit(false);

    setShowToast(true);
    setTimeout(() => setShowToast(false), 5000);
  };

  const [showEdit, setShowEdit] = useState(false);
  const [showToast, setShowToast] = useState(false);

  return (
    <>
      <Header />

      <main className="bg-white overflow-hidden">
        <section className="flex flex-col items-center justify-center p-6 mb-16">
          <div id="setdonation" className="max-w-xl text-center">
            <motion.h2
              className="mt-24 text-4xl sm:text-6xl font-light"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "tween", duration: 1 }}
            >
              @{userHandle}
            </motion.h2>
            <p className="font-serif text-2xl sm:text-4xl my-4">
              You have {photoArray.length} photos with nature.
              Here the value of your partnership with nature:
            </p>
            <div className="flex gap-1 items-center justify-center font-druk text-6xl sm:text-8xl font-black ">
              {/* {!showEdit && (
                <div className="flex items-center gap-3">
                  {"$"}{" "}
                  <CountUp end={donation} preserveValue={true} duration={0.5} />{" "}
                  <button onClick={() => setShowEdit(true)}>
                    <PencilIcon className=" flex-shrink-0 w-8 h-8 p-1 rounded-full bg-gray-900 text-yellow-50 hover:bg-gray-600 cursor-pointer " />
                  </button>
                </div>
              )}
              {showEdit && (
                <div>
                  <form
                    className="flex items-center gap-3"
                    onSubmit={updateDonation}
                  >
                    {"$"}{" "}
                    <input
                      type="number"
                      min={MIN_PAYMENT_VALUE}
                      max={MAX_PAYMENT_VALUE}
                      placeholder={`${donation}`}
                      className="block w-full p-0 border-t-0 border-r-0 border-l-0 border-b-2 text-center text-6xl sm:text-8xl font-druk font-black placeholder-gray-900"
                      onChange={(e) => setDonation(e.target.value)}
                      onBlur={updateDonation}
                    />
                    <button type="submit">
                      <CheckCircleIcon className=" flex-shrink-0 w-8 h-8 p-1 rounded-full bg-gray-900 text-yellow-50 hover:bg-gray-600 cursor-pointer " />
                    </button>
                  </form>
                </div>
              )} */}
              <p className="flex gap-2 items-center font-druk text-5xl sm:text-8xl font-black ">
                $ <CountUp end={donation} preserveValue={true} duration={0.5} />
              </p>
            </div>

            <p className="text-sm my-10">
              * But you can pay nature back how much you choose. <br/> All profits
              will go to NGO&#39;s supporting nature. You can read more{" "}
              <Link to="/ngos" className="underline hover:text-gray-500">
                here
              </Link>
              .
            </p>

            {/* We need to send the {donation} as the price_id to the checkout session (see views.py) */}
            {/* <form action="/checkout-session/" method="POST">
              <input type="hidden" name="donationName" value={userHandle} />
              <input
                type="hidden"
                name="donationAmount"
                value={donation * 100}
              />
              <input type="hidden" name="jobId" value={jobId} />
              <input
                type="hidden"
                name="csrfmiddlewaretoken"
                value={getCookie("csrftoken")}
              />
              <button className="text-yellow-50 bg-gray-900 text-2xl font-light py-2 px-4 rounded-full  hover:bg-yellow-100 hover:text-gray-900 transition-all">
                Donate Now
              </button>
            </form>
            <Toast
              title="Donation set!"
              description={`Your donation is now $${donation}`}
              type="success"
              show={showToast}
            /> */}

            <a href="/ngos">
              <button className="text-yellow-50 bg-gray-900 text-2xl font-light py-2 px-4 rounded-full  hover:bg-yellow-100 hover:text-gray-900 transition-all">
                Donate Now
              </button>
            </a>
          </div>
        </section>

        <section className=" flex flex-col items-center justify-center text-yellow-50 sky-bg bg-scroll bg-left-bottom m-2 sm:m-6 py-12 rounded-xl">
          <div className="">
            <motion.div
              className=" bg-white text-gray-900 p-6 sm:p-12 rounded-lg text-center shadow-2xl -mt-24 mx-4 sm:mx-0
              text-sm sm:text-base"
              initial={{ y: 100, opacity: 0, rotate: 0 }}
              whileInView={{ y: 1, opacity: 1, rotate: 0 }}
              viewport={{ once: true }}
              transition={{
                type: "spring",
                bounce: 0.5,
                duration: 2,
                delay: 0.5,
                mass: 5,
              }}
            >
              <div className="grid grid-cols-2 justify-between font-light sm:gap-60">
                <p className="text-left ">
                  From:
                  <br />
                  Mother Nature
                  <br /> 3rd planet from the Sun, <br />
                  Milky Way
                </p>
                <p className="text-right text-xl sm:text-4xl ">
                  Bill
                  <br />
                  {"#"} {invoiceNo}
                </p>
              </div>

              <hr className="my-4 sm:my-10" />

              <div className="grid grid-cols-2 justify-between">
                <p className="text-left">
                  Bill to:
                  <br />@{userHandle}
                </p>

                <div className="text-gray-500 text-sm">
                  <div className="flex justify-between">
                    <p>Bill</p>
                    <p>
                      {"#"} {invoiceNo}
                    </p>{" "}
                  </div>
                  <div className="flex justify-between">
                    <p>Issue date</p>
                    <p>{date}</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Due date</p>
                    <p>{date}</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Amount due</p>
                    <p>${photoArray.length * VALUE_PER_IMAGE}</p>
                  </div>
                </div>
              </div>

              <hr className="my-4 sm:my-10" />

              <div
                className="grid gap-2 overflow-y-auto"
                style={{ maxHeight: MAX_IMAGES_SHOWING_INVOICE * 56 }}
              >
                {photoArray.map((photo) => (
                  <div
                    key={photo.id}
                    className="flex justify-between gap-2 items-center text-xs sm:text-sm text-gray-500"
                  >
                    <div className="flex gap-4 items-center">
                      <img
                        alt="Nature"
                        src={photo.src}
                        className="rounded-lg w-12 h-12"
                      />
                      {photo.id} Detected: {photo.tag}
                    </div>
                    {/* To do - get date from post or remove */}
                    {/* <p>Posted on Dec 2,2021</p> */}
                    <p>${VALUE_PER_IMAGE}</p>
                  </div>
                ))}
              </div>

              <hr className="my-4 sm:my-6" />

              <div className="flex justify-between text-base">
                <p>Total due</p>
                <p>${donation}</p>
              </div>

              <hr className="my-4 sm:my-6" />
              <a href="/ngos">
                <button className="text-yellow-50 bg-gray-900 text-2xl font-light py-2 px-4 rounded-full  hover:bg-yellow-100 hover:text-gray-900 transition-all">
                  Donate Now
                </button>
              </a>
              <hr className="my-4 sm:my-10" />

              <div className="flex justify-between text-xs gap-3 ">
                <p className="text-left">
                  <strong>Payments</strong>
                  <br />
                  Donations only <br />
                </p>
                <p className="text-right ">
                  <p>
                    <strong>About</strong>
                    <br />
                    A project build by grAIdient
                    <br />
                    and designed by uncommon
                    <br />
                    contact@uncommon.com
                  </p>
                </p>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="flex flex-col gap-10 justify-center items-center p-6 lg:p-20 w-full">
          <h4 className="text-center text-4xl sm:text-5xl font-light">
            Nature was your influencer <br />
            <span className="font-serif">
              in these {photoArray.length} Photos
            </span>
          </h4>
          <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-12 items-center justify-center sm:w-3/4 w-full ">
            {photoArray.map((photo, photoIndex) => (
              <div key={photo.id} className="w-80 sm:w-full z-10">
                <div className="z-0 flex flex-col">
                  <div className="flex justify-end -mb-12 m-4 z-50 text-yellow-50 relative">
                    <button
                      className="text-right text-xs py-2 px-3 text-yellow-50 bg-gray-800 rounded-full hover:opacity-80 active:opacity-100 cursor-pointer"
                      onClick={() => flagImage(photoIndex)}
                    >
                      {photo.flagged ? (
                        <p className="text-red-500 flex gap-1 items-center">
                          <FlagIcon className="w-4 " /> Flagged
                        </p>
                      ) : (
                        <p className="flex gap-1 items-center">
                          Not Nature?
                          <FlagIcon className="w-4 text-red-500" />
                        </p>
                      )}
                    </button>
                  </div>

                  <img
                    alt={photo.tag}
                    src={photo.src}
                    className="rounded-lg w-full z-0 shadow"
                  />
                  <p className=" z-40 bg-gray-800 -mt-12 ml-4 mb-2 py-2 px-3 text-yellow-50 rounded-full text-xs max-w-max">
                    <span className="text-green-500 mr-2 animate-pulse">•</span>
                    {photo.tag}
                  </p>
                </div>

                <p class="mt-8">
                  You owe nature{" "}
                  {photo.flagged ? (
                    <strong>$0</strong>
                  ) : (
                    <strong>
                      $<CountUp end={VALUE_PER_IMAGE} duration={2} />
                    </strong>
                  )}{" "}
                  for this photo
                </p>
              </div>
            ))}
          </div>
          <p className="text-center text-2xl mt-10 font-serif">
            Your total comes to:
          </p>
          <p className="flex gap-2 items-center font-druk text-5xl sm:text-8xl font-black ">
            $<CountUp end={donation} duration={3} />{" "}
          </p>
          <p className="text-sm text-center">
            All profits will go to NGO&#39;s supporting nature. You can read
            more{" "}
            <Link to="/ngos" className="underline hover:text-gray-500">
              here
            </Link>
            .
          </p>
          <a href="/ngos">
            <button className="text-yellow-50 bg-gray-900 text-2xl font-light py-2 px-4 rounded-full  hover:bg-yellow-100 hover:text-gray-900 transition-all">
              Donate Now
            </button>
          </a>
        </section>

        <section className="text-center p-10">
          <p className="font-serif text-4xl mb-3">
            Share this post on social media
          </p>
          <div className="flex items-center justify-center gap-3 ">
            <div className="flex items-center p-2 border rounded-full hover:bg-yellow-50">
              <FacebookShareButton
                url="https://natureinfluencer.com"
                size={32}
                quote={`Thank you, Nature, for your content creation services.
                Scan your profile and see how much your own.`}
                hashtag="unpaidpartnershipwithNature"
                className="p-2 border rounded-full hover:bg-white "
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
            </div>
            <div className="flex items-center p-2 border rounded-full hover:bg-yellow-50">
              <TwitterShareButton
                url="https://natureinfluencer.com"
                size={32}
                title={`Thank you, Nature, for your content creation services.
                Scan your profile and see how much your own.`}
                hashtag="unpaidpartnershipwithNature"
                className="p-2 border rounded-full hover:bg-white "
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
            </div>

            <div className="flex items-center p-2 border rounded-full hover:bg-yellow-50">
              <WhatsappShareButton
                url="https://natureinfluencer.com"
                size={32}
                title={`Thank you, Nature, for your content creation services.
                Scan your profile and see how much your own.`}
                hashtag="unpaidpartnershipwithNature"
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
export default InvoicePage;
