import { useRef } from "react";
import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import Footer from "../../components/Footer";
import NGOs from "../../components/NGOs";
import { useNavigate, Link } from "react-router-dom";

import loveIcon from "../../assets/media/love.svg";
import likeIcon from "../../assets/media/like.svg";
import hahaIcon from "../../assets/media/haha.svg";
import ninetyPercentIcon from "../../assets/media/90percent.svg";
import billionsIcon from "../../assets/media/13billion.svg";
import eightyPercentIcon from "../../assets/media/80percent.svg";

import howdoesitworkImage1 from "../../assets/media/howdoesitwork1.webp";
import howdoesitworkImage2 from "../../assets/media/howdoesitwork2.webp";
import howdoesitworkImage3 from "../../assets/media/howdoesitwork3.webp";

import profilePic from "../../assets/media/profilePic.png";
import reactionsPic from "../../assets/media/reactions.png";
import invoicePic from "../../assets/media/invoice.webp";

import card1Pic from "../../assets/media/post1.webp";
import card2Pic from "../../assets/media/post2.webp";
import card3Pic from "../../assets/media/post3.webp";
import card4Pic from "../../assets/media/post4.webp";
import card5Pic from "../../assets/media/post5.webp";

import verticalVideo from "../../assets/media/videoVertical.mp4";
import invoiceVideo from "../../assets/media/invoiceVideo.mp4";
import natureVideo from "../../assets/media/PartnershipWithNature.mp4";
import backgroundIvoiceMobileVideo from "../../assets/media/backgroundInvoiceMobile.mp4";

import herb from "../../assets/emojis/herb.webp";
import plant from "../../assets/emojis/plant.webp";
import dove from "../../assets/emojis/dove.webp";
import handbag from "../../assets/emojis/handbag.webp";
import mountain from "../../assets/emojis/mountain.webp";
import moneyWings from "../../assets/emojis/moneyWings.webp";
import volcano from "../../assets/emojis/volcano.webp";
import globe from "../../assets/emojis/globe.webp";

import Menu from "../../components/Menu";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 50 },
  show: { opacity: 1, y: 0 },
};

function Home() {
  const navigate = useNavigate();
  const natureVideoRef = useRef(null);

  const socialLogin = () => {
    if (window.social_url) {
      window.location.href = window.social_url;
    } else {
      navigate("/invoice");
    }
  };

  return (
    <div className="App">
      <div className="bg-white font-sans">
        <main className="overflow-hidden">
          <Menu />

          <motion.section
            className=" text-white mt-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 3 }}
          >
            <div className="flex flex-col items-center justify-center mx-4 sm:mx-6 hero bg-cover bg-bottom p-10 sm:py-12 rounded-xl">
              <div className="relative w-full z-40">
                {/* <motion.div
                  className="absolute sm:top-40 sm:left-44 top-64 left-4"
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 1, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    type: "spring",
                    bounce: 0.5,
                    duration: 1,
                    delay: 2,
                    // repeat: Infinity,
                    // repeatDelay: 4,
                  }}
                >
                  <p className="py-2 px-3 bg-gray-800 rounded-lg text-sm">
                    <span className="text-green-500 mr-2 animate-pulse">•</span>
                    Detected: Mountains
                  </p>
                </motion.div> */}
                <motion.div
                  className="absolute top-120 left-1/2 sm:left-2/4 sm:ml-32 min-w-max"
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 1, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    type: "spring",
                    bounce: 0.5,
                    duration: 1,
                    delay: 2.8,
                    // repeat: Infinity,
                    // repeatDelay: 3,
                  }}
                >
                  <p className="py-2 px-3 backdrop-blur-sm bg-white/60 text-black rounded-lg text-sm border border-gray-200">
                    <span className="text-green-500 mr-2 animate-pulse">•</span>{" "}
                    Detected: Meadow
                  </p>
                </motion.div>
                <motion.div
                  className="absolute top-80 left-20 sm:left-2/4 ml-6 sm:-ml-56"
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 1, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    type: "spring",
                    bounce: 0.5,
                    duration: 1,
                    delay: 2.6,
                    // repeat: Infinity,
                    // repeatDelay: 5,
                  }}
                >
                  <p className="py-2 px-3  backdrop-blur-sm bg-white/60 text-black rounded-lg text-sm border border-gray-200">
                    <span className="text-green-500 mr-2 animate-pulse">•</span>{" "}
                    Detected: Hills
                  </p>
                </motion.div>
                {/* <motion.div
                  className="absolute top-44 sm:top-1/4 left-3/4 -ml-12"
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 1, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    type: "spring",
                    bounce: 0.5,
                    duration: 1,
                    delay: 2,
                    // repeat: Infinity,
                    // repeatDelay: 4,
                  }}
                >
                  <p className="py-2 px-3 bg-gray-800 rounded-lg text-sm min-w-max">
                    <span className="text-green-500 mr-2 animate-pulse">•</span>{" "}
                    Detected: Sky
                  </p>
                </motion.div> */}
              </div>{" "}
              <div className="flex flex-col gap-2 items-center z-20 text-center font-sans font-light bg-clip-text drop-shadow-md">
                <div className="flex flex-col gap-1 mb-5 -mt-4">
                  {/* <img src={icon} className="w-10 -ml-6" alt="Icon" /> */}
                  <h1 className="text-5xl sm:text-6xl font-medium">Nature</h1>
                  <motion.span
                    className="text-2xl sm:text-4xl font-medium"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{
                      type: "spring",
                      bounce: 0.5,
                      duration: 2,
                      delay: 0.2,
                      // repeat: Infinity,
                      // repeatDelay: 4,
                    }}
                  >
                    The world’s biggest influencer
                  </motion.span>
                </div>
              </div>
              <motion.div
                className="flex flex-col items-center justify-center hover:rotate-0 transition-all overflow-hidden border border-gray-300 rounded-3xl shadow-lg"
                initial={{ y: -50, rotate: 0, scale: 1.05, opacity: 0 }}
                whileInView={{
                  y: 0,
                  rotate: 4,
                  scale: 1,
                  opacity: 1,
                }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  mass: 10,
                  duration: 3,
                  delay: 1,
                }}
                whileHover={{ rotate: 0 }}
              >
                <img
                  src={profilePic}
                  className="w-32 z-20 mt-3 -mb-28 -ml-40 sm:-ml-56"
                  alt="Profile Pic"
                />
                <div className="z-1 grid items-center justify-center max-w-xs sm:max-w-sm pt-12 pb-4">
                  <video autoPlay loop muted playsInline defaultMuted>
                    <source src={verticalVideo} type="video/mp4" /> Your browser
                    does not support HTML5 video.
                  </video>
                </div>
                <img
                  src={reactionsPic}
                  className="w-44 z-20 -mt-14 mb-4 sm:-ml-44 -ml-32"
                  alt="Reactions"
                />
              </motion.div>
              <p className="mt-3 text-5xl sm:text-6xl z-0 text-center font-serif font-light">
              Save the planet with AI
              </p>
              <p className="mt-3 text-3xl sm:text-4xl z-0 text-center font-serif font-light leading-snug">
              (and your donations)
              </p>
              <div className="flex items-center  justify-center flex-wrap gap-2 mt-6 ">
                <button
                  onClick={socialLogin}
                  className="bg-gray-900 text-lg sm:text-2xl font-light py-2 px-4 rounded-full  hover:bg-yellow-50 hover:text-gray-900 transition-all"
                >
                  Link your Instagram
                </button>

                <button
                  onClick={(id) =>
                    natureVideoRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "end",
                    })
                  }
                  className="bg-gray-900 text-lg sm:text-2xl font-light py-2 px-4 rounded-full  hover:bg-yellow-50 hover:text-gray-900 transition-all"
                >
                  Learn More
                </button>
              </div>
            </div>
          </motion.section>

          <section className="flex justify-center items-center">
            <div className="grid text-left py-20 px-16 max-w-7xl">
              <div className="flex">
                <div>
                  <motion.p
                    className="text-5xl sm:text-6xl"
                    // initial={{ y: 100, opacity: 0 }}
                    // whileInView={{ y: 1, opacity: 1 }}
                    // viewport={{ once: true }}
                    // transition={{
                    //   type: "spring",
                    //   bounce: 0.5,
                    //   duration: 1,
                    //   delay: 0,
                    // }}
                  >
                    More Nature,
                  </motion.p>
                  <p className="text-6xl sm:text-7xl font-serif mb-4">
                    more likes
                  </p>
                </div>
                <span className="text-8xl mt-8 -ml-10">
                  <img src={plant} className="w-24" />
                </span>
              </div>

              <div className="grid md:grid-cols-2">
                <div></div>
                <p className="text-2xl sm:text-4xl font-serif ">
                  Nature is the biggest backdrop of our social profiles. Partner with Nature for love, likes and engagements - Nature is the world's greatest influencer.
                  <img
                    src={loveIcon}
                    className="inline transform rotate-12 w-12 h-12 -my-2 mx-2"
                    alt="love emoji"
                  />
                  <img
                    src={likeIcon}
                    className="inline transform rotate-12 w-12 h-12 -my-2 mx-2"
                    alt="like"
                  />
                  <img
                    src={hahaIcon}
                    className="inline transform rotate-12 w-12 h-12 -my-2 mx-2"
                    alt="haha"
                  />
                </p>
              </div>
            </div>
          </section>

          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "tween", duration: 1 }}
            className="rounded-xl m-4 sm:m-10 overflow-hidden relative flex justify-center"
            ref={natureVideoRef}
          >
            <video autoPlay loop muted playsInline defaultMuted controls>
              <source src={natureVideo} type="video/mp4" /> Your browser does
              not support HTML5 video.
            </video>
          </motion.div>

          <section className="flex justify-center items-center">
            <div className="grid text-left w-full py-20  px-16 sm:px-20 max-w-7xl">
              <div className="flex">
                <div>
                  <p className="text-5xl sm:text-7xl">
                    How does Your Partnership{" "}
                  </p>
                  <div className="flex">
                    <p className="text-5xl sm:text-7xl font-serif mb-4">
                      with Nature work?
                    </p>
                    <span className="text-8xl -ml-6 -mt-2 inline">
                      <img src={herb} className="w-24" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid md:grid-cols-3 gap-6 mt-10">
                <div className="flex flex-col sm:items-center sm:justify-center gap-3 text-left sm:text-center">
                  <img
                    src={howdoesitworkImage1}
                    className=" w-56 shadow-xl rounded-md transform rotate-6"
                  />
                  {/* <p className="text-xl font-serif mt-6 w-full ">Step 1</p> */}
                  <p className="text-3xl w-full font-serif">Link</p>
                  <p className=" font-light ">
                    Link your Instagram account with Your Partnership with
                    Nature.
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center gap-3 sm:text-center">
                  <img src={howdoesitworkImage2} />
                  {/* <p className="text-xl font-serif w-full">Step 2</p> */}
                  <p className="text-3xl w-full font-serif">Scan</p>
                  <p className="w-full font-light ">
                  Let's have our friendly AI algorithm peek at your profile for
nature photos to see how you can contribute!
                  </p>
                </div>
                <div className="flex flex-col items-center sm:justify-center gap-3 sm:text-center">
                  <img
                    src={howdoesitworkImage3}
                    className="w-56 shadow-xl rounded-md transform -rotate-6"
                  />
                  {/* <p className="text-xl font-serif  mt-8 w-full">Step 3</p> */}
                  <p className="text-3xl w-full font-serif ">Donate</p>
                  <p className=" font-light sm:text-center ">
                    Find out how much you owe Nature and take action.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="sm:my-20 sm:py-10 mb-20">
            <h3 className="text-6xl  text-center mb-20">Let AI do its magic</h3>
            <div className="grid grid-cols-3 sm:grid-cols-5 items-center justify-center">
              <motion.div
                className="z-10 w-56 sm:w-full"
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 1,
                  delay: 0,
                }}
              >
                <img
                  className=" rounded-lg transform rotate-3 shadow-lg"
                  src={card1Pic}
                  alt="Picture"
                />
              </motion.div>
              <motion.div
                className="z-0 w-56 sm:w-full"
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 1,
                  delay: 0.3,
                }}
              >
                <img
                  className=" rounded-lg transform -rotate-3 -mt-10 mb-10  shadow-lg"
                  src={card2Pic}
                  alt="Picture"
                />
              </motion.div>
              <motion.div
                className="z-0 w-56 sm:w-full"
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 1,
                  delay: 0.5,
                }}
              >
                <img
                  className="z-10 rounded-lg transform rotate-2  shadow-lg"
                  src={card3Pic}
                  alt="Picture"
                />
              </motion.div>
              <motion.div
                className="z-0 w-56 sm:w-full"
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 1,
                  delay: 0.8,
                }}
              >
                <img
                  className=" rounded-lg transform -rotate-6  mb-10 ml-10 sm:ml-5 sm:-mt-10 shadow-lg"
                  src={card4Pic}
                  alt="Picture"
                />
              </motion.div>
              <motion.div
                className="z-0 w-56 sm:w-full"
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 1,
                  delay: 1,
                }}
              >
                <img
                  className="rounded-lg transform rotate-2  shadow-lg ml-12 sm:m-0"
                  src={card5Pic}
                  alt="Picture"
                />
              </motion.div>
            </div>
            <div className="flex flex-col px-10 gap-4 items-center justify-center mt-20 text-sm  text-center">
              <button
                onClick={socialLogin}
                className="px-5 py-3 rounded-full text-white text-xl font-light bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500 hover:opacity-80 active:opacity-100 transition-all"
              >
                Connect with Facebook
              </button>
              <p className="sm:text-xl font-light">
              Let's have our friendly AI algorithm peek at your profile for nature photos to see how you can contribute!
              </p>
            </div>
          </section>

          <section className="relative flex items-center justify-center m-6 py-12 rounded-xl text-white invoice-bg bg-cover bg-right-bottom">
            <div className="flex flex-col items-center justify-center z-30 ">
              <motion.img
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 2,
                  delay: 0,
                }}
                src={invoicePic}
                className="-ml-16 sm:ml-20 z-50 mt-10 sm:-mt-32 max-w-xs sm:max-w-2xl"
              />
              <p className="p-4 text-4xl sm:text-6xl font-light text-center">
                What&#39;s your <br />
                <span className="font-serif text-5xl sm:text-7xl">
                  fair share?
                </span>
              </p>
              <button
                onClick={socialLogin}
                className="bg-gray-900 text-2xl font-light py-2 px-4 rounded-full mt-3 hover:bg-yellow-50 hover:text-gray-900 transition-all"
              >
                Get your Estimate
              </button>
            </div>
            <div className="flex items-center justify-center min-w-full max-h-full max-w-none absolute z-10 sm:m-6 rounded-xl overflow-hidden">
              <video
                autoPlay
                loop
                muted
                playsInline
                defaultMuted
                className="w-auto min-w-full max-h-full min-h-full hidden sm:block"
              >
                <source src={invoiceVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <video
                autoPlay
                loop
                muted
                playsInline
                defaultMuted
                className="w-auto min-w-full min-h-screen sm:hidden"
              >
                <source src={backgroundIvoiceMobileVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </section>

          <section className="flex justify-center items-center">
            <div className="grid text-left py-24 sm:py-28 px-16 max-w-7xl">
              <div className="grid z-10">
                <div className="flex ">
                  <p className="text-5xl sm:text-6xl font-light">Pay Nature</p>{" "}
                  <span className="text-8xl -ml-3 -mt-8">
                    <img src={mountain} className="w-24" />
                  </span>
                </div>
                <p className="text-6xl sm:text-7xl font-serif  sm:mt-0 mb-6">
                  what Nature <br />
                  is owed
                </p>
              </div>

              <div className="grid md:grid-cols-2 z-0">
                <div></div>
                <p className="text-xl sm:text-4xl font-serif ">
                  Our AI algorithm calculates how influential Nature has been
                  for your social media profile and generates a personalized
                  bill that details how much you owe Nature for the engagement
                  it generated on your profile.
                </p>
              </div>
            </div>
          </section>

          <section className=" flex flex-col items-center justify-center text-white sky-bg bg-cover bg-left-bottom  m-6 p-12 rounded-xl overflow-hidden">
            <div className="grid sm:grid-cols-2 md:grid-cols-3 items-center gap-6">
              <motion.div
                className="flex flex-col justify-center items-center bg-white text-gray-900 p-6 rounded-lg text-center max-w-lg shadow-2xl cursor-move
            "
                initial={{ y: 100, opacity: 0, rotate: 45 }}
                whileInView={{ y: 30, opacity: 1, rotate: -8 }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 1,
                  delay: 1,
                }}
                // drag
                // dragConstraints={{
                //   top: -50,
                //   left: -50,
                //   right: 50,
                //   bottom: 50,
                // }}
              >
                <a
                  className="text-xs text-gray-500 cursor-pointer hover:text-gray-900"
                  href="https://influencermarketinghub.com/influencer-marketing-statistics/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://influencermarketinghub.com/influencer-marketing-statistics/
                </a>
                <img src={ninetyPercentIcon} className="mt-4" />
                <div className="text-9xl mt-3 sm:mt-20 -mb-3">
                  <img src={moneyWings} className="w-28" />
                </div>
                {/* <span className="px-3 py-2 bg-transparent border-2 border-green-500 text-green-600 rounded-full ml-12 transform rotate-2 ">
                  #RICH
                </span> */}
                <p className="text-xl sm:text-3xl mt-6 sm:mt-20">
                  of respondents believe influencer marketing to be an effective
                  form of marketing
                </p>
              </motion.div>

              <motion.div
                className="order-first sm:order-none flex-shrink-0 flex flex-col items-center bg-white text-gray-900 p-6 rounded-lg text-center max-w-lg shadow-2xl z-10 cursor-move"
                initial={{ y: 100, opacity: 0, rotate: 45 }}
                whileInView={{ y: 1, opacity: 1, rotate: 2 }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 1,
                  delay: 0.2,
                }}
                // drag
                // dragConstraints={{
                //   top: -50,
                //   left: -50,
                //   right: 50,
                //   bottom: 50,
                // }}
              >
                <p className="text-xl sm:text-3xl mt-4">
                  In 2021 the influencer business is expected to reach
                </p>
                <div className="text-9xl mt-6 sm:mt-20 -mb-3">
                  <img src={volcano} className="w-28" />
                </div>
                {/* <div className="w-44 px-3 py-2 bg-transparent border-2 border-green-500 text-green-600 rounded-full ml-12 transform -rotate-3">
                  #UNNATURAL?
                </div> */}
                <img src={billionsIcon} className="mt-6 sm:mt-20 mb-3" />
                <a
                  className="text-xs text-gray-500 mb-3 cursor-pointer hover:text-gray-900"
                  href="https://influencermarketinghub.com/influencer-marketing-statistics/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://influencermarketinghub.com/influencer-marketing-statistics/
                </a>
              </motion.div>

              <motion.div
                className="flex flex-col justify-center items-center bg-white text-gray-900 p-6 rounded-lg text-center max-w-lg shadow-2xl
                cursor-move "
                initial={{ y: 100, opacity: 0, rotate: 45 }}
                whileInView={{ y: 30, opacity: 1, rotate: 8 }}
                viewport={{ once: true }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 1,
                  delay: 1.5,
                }}
                //drag
                // dragConstraints={{
                //   top: -50,
                //   left: -50,
                //   right: 50,
                //   bottom: 50,
                // }}
              >
                <a
                  className="text-xs text-gray-500 mb-3 cursor-pointer hover:text-gray-900"
                  href="https://www.ion.co/grin-report-the-power-of-influencer-marketing"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.ion.co/grin-report-the-power-of-influencer-marketing
                </a>
                <img src={eightyPercentIcon} className="mt-4" />
                <div className="text-9xl mt-4 lg:mt-20 -mb-3">
                  <img src={handbag} className="w-28" />
                </div>
                {/* <div className="text-xs lg:text-base w-44 px-3 py-2 bg-transparent border-2 border-green-500 text-green-600 rounded-full ml-12 transform rotate-3">
                  #CONSUMPTION
                </div> */}
                <p className="text-xl lg:text-3xl mt-6 lg:mt-20">
                  of consumers are more inclined to buy products that
                  influencers promote”
                </p>
              </motion.div>
            </div>

            <p className="text-4xl sm:text-6xl mt-20 sm:mt-12 text-center">
              How much do you
              <br />{" "}
              <span className="text-5xl sm:text-7xl font-serif">
                owe Nature?
              </span>
            </p>
            <button
              onClick={socialLogin}
              className="bg-gray-900 text-2xl font-light py-2 px-4 rounded-full mt-6 hover:bg-yellow-50 hover:text-gray-900 transition-all"
            >
              Get your Estimate
            </button>
          </section>

          {/* <section className="flex justify-center items-center">
            <div className="grid text-left py-20 px-8 max-w-7xl">
              <div className="flex">
                <div className="z-10">
                  <p className="sm:block inline text-4xl sm:text-6xl font-light">
                    Where does all{" "}
                  </p>
                  <p className="inline text-5xl sm:text-7xl font-serif">
                    the money go?
                  </p>
                </div>
                <span className="text-8xl mt-8 -ml-6">
                  <img src={dollar} className="w-24" />
                </span>
              </div>

              <div className="grid md:grid-cols-2 mt-4">
                <div></div>
                <div>
                  <p className="text-2xl sm:text-4xl font-serif ">
                    We work with NGOs that champion saving Nature to deliver
                    your donation accurately, based on the tags that our AI
                    identifies in your photos.
                  </p>
                </div>
              </div>
            </div>
          </section> */}

          {/* 
          //Removed for now
          
          <NGOs limit={3} /> 

          <section className="flex justify-center items-center">
            <div className="grid pt-2 pb-24 px-8 max-w-7xl w-full justify-start">
              <div className="">
                <Link
                  to="/ngos"
                  className=" text-left hover:opacity-60 underline"
                >
                  View all NGOs
                </Link>
              </div>
            </div>
          </section>*/}

          <motion.section
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              type: "spring",
              bounce: 0.5,
              duration: 1,

              mass: 2,
            }}
            className="flex justify-center items-center  bg-gradient-to-b from-green-500 to-green-600 bottomBg bg-contain bg-no-repeat bg-bottom m-6 rounded-xl"
          >
            <div className="grid text-center text-yellow-50 py-40 px-8 max-w-7xl">
              <div className="flex flex-col items-center">
                <span className=" -mb-8">
                  <img src={globe} className="w-24" />
                </span>
                <div className="z-10">
                  <p className="sm:block inline text-5xl sm:text-6xl ">
                    Supporting Nature
                    <br />
                  </p>
                  <p className="inline text-5xl sm:text-7xl font-serif">
                    one donation at a time
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-2 items-center">
                <button
                  onClick={socialLogin}
                  className="text-gray-900 bg-yellow-50 text-2xl font-light py-2 px-4 rounded-full mt-6 hover:text-yellow-50 hover:bg-gray-900 transition-all max-w-max"
                >
                  Partner With Nature
                </button>
              </div>
            </div>
          </motion.section>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
