import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import React from "react";

export default function Privacy() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className=" flex items-center justify-center ">
        <button
          type="button"
          onClick={openModal}
          className="text-gray-500 mt-6 text-xs hover:text-gray-300 cursor-pointer"
        >
          Privacy
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto "
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="border inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Privacy
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    We store all your data securely and don't share it with
                    third parties. View our full privacy policy and terms and
                    conditions:
                  </p>
                  <div className="flex gap-2 flex-wrap mt-3">
                    <a href="/privacy">
                      <span className="text-blue-500 underline">
                        Privacy Policy
                      </span>
                    </a>
                    <a href="/terms">
                      <span className="text-blue-500 underline">
                        Terms and Conditions
                      </span>
                    </a>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="text-sm font-medium text-blue-900"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
