/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Popover, Transition, Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import icon from "../assets/media/icon.svg";
import globe from "../assets/emojis/globe.webp";
import globeRotating from "../assets/media/faviconNature.gif";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Menu() {
  const navigate = useNavigate();

  const socialLogin = () => {
    if (window.social_url) {
      window.location.href = window.social_url;
    } else {
      navigate("/invoice");
    }
  };

  const userHandle = window.username;

  return (
    <Popover className="relative bg-white z-50 font-sans">
      <div className="flex justify-between items-center px-4 pt-6 pb-2 sm:px-6 md:justify-start md:space-x-10">
        <div className="flex justify-start md:justify-start lg:w-0 lg:flex-1 cursor-pointer w-full">
          <a
            className="flex gap-2 items-center text-xl text-gray-800  transition-all"
            href="/"
            rel=" noopener"
          >
            {/* */}
            <a href="/" className="relative ring-0">
              <div className="absolute w-10 h-10 top-0 right-0 ring-2 ring-green-500 rounded-full ml-1 animate-pulse "></div>
              <img
                alt="NatureIcon"
                src={globeRotating}
                className="w-10 h-10 flex-shrink-0 hover:opacity-75 cursor-pointer p-0.5 transition-all"
              />
            </a>
            <h1 className="text-base sm:text-lg text-left inline sm:flex items-center gap-1 hover:opacity-60 transition-all">
              {userHandle ? "@" + userHandle + "'s" : "Your"} Partnership with{" "}
              <span className="flex gap-1 items-center font-medium">
                Nature
                <img
                  alt="NatureIcon"
                  src={icon}
                  className="w-5 h-5 flex-shrink-0"
                />
              </span>
            </h1>
          </a>
        </div>

        <div className="-mr-2 -my-2 lg:hidden">
          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <Popover.Group as="nav" className="hidden lg:flex space-x-10">
          <button
            onClick={socialLogin}
            className="text-base text-gray-500 hover:text-gray-900 text-left"
          >
            <span className="text-blue-500 mr-2 animate-pulse">•</span> Your
            Contribution{" "}
          </button>
          <a
            href="/why-nature"
            className="text-base  text-gray-500 hover:text-gray-900"
          >
            Why Nature?
          </a>

          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? "text-gray-900" : "text-gray-500",
                    "group bg-white rounded-md inline-flex items-center text-base hover:text-gray-900 focus:outline-none "
                  )}
                >
                  <span>About us</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? "text-gray-600" : "text-gray-400",
                      "ml-2 h-5 w-5 group-hover:text-gray-500"
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 min-w-max max-w-xs sm:px-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 text-left">
                        <a
                          href="/behind-the-scenes"
                          className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                        >
                          <p className="text-basetext-gray-900 capitalize">
                            Behind The Scenes
                          </p>
                          <p className="mt-1 text-sm text-gray-500 capitalize">
                            The AI in the backend
                          </p>
                        </a>{" "}
                        <a
                          href="/ngos"
                          className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                        >
                          <p className="text-base font-normal text-gray-900 capitalize">
                            Nature organizations
                          </p>
                          <p className="mt-1 text-sm text-gray-500 capitalize">
                            Choose a Cause
                          </p>
                        </a>
                        <a
                          href="/press"
                          className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                        >
                          <p className="text-base font-normal text-gray-900 capitalize">
                            Press Kit
                          </p>
                          <p className="mt-1 text-sm text-gray-500 capitalize">
                            Spread the word about Nature
                          </p>
                        </a>
                        <a
                          href="/faq"
                          className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                        >
                          <p className="text-base font-normal text-gray-900">
                            Frequent Questions
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            Nature FAQs
                          </p>
                        </a>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <Popover className="relative ">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? "text-gray-900" : "text-gray-500",
                    "group bg-white rounded-md inline-flex items-center text-base font-normal hover:text-gray-900 focus:outline-none "
                  )}
                >
                  <span>Get involved</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? "text-gray-600" : "text-gray-400",
                      "ml-2 h-5 w-5 group-hover:text-gray-500"
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 left-1/2 transform  origin-top-right -translate-x-3/4 mt-3 px-2 min-w-max max-w-xs sm:px-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 text-left">
                        {/* <a
                          href="/donate"
                          className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                        >
                          <p className="text-base font-normal text-gray-900">
                            Donate
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            Send a direct donation
                          </p>
                        </a> */}
                        <a
                          href="/contact"
                          className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                        >
                          <p className="text-base font-normal text-gray-900">
                            Contact us
                          </p>
                          <p className="mt-1 text-sm text-gray-500">
                            Send us a message
                          </p>
                        </a>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </Popover.Group>
      </div>

      {/* Mobile menu */}
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <a href="/" className="relative ">
                    <img
                      alt="NatureIcon"
                      src={globeRotating}
                      className="w-10 h-10 flex-shrink-0 hover:opacity-75 cursor-pointer p-0.5 transition-all"
                    />
                  </a>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid grid-cols-1 gap-7 text-left">
                  <button
                    onClick={socialLogin}
                    className="hover:text-blue-600 text-left"
                  >
                    Your Bill
                    {/* <span className="text-blue-500 mr-2 animate-pulse">•</span> */}
                  </button>
                  <a href="/why-nature" className="hover:text-blue-600">
                    Why Nature?
                  </a>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button>
                          <span className="flex gap-2 justify-between hover:text-blue-600">
                            About
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } w-5 h-5`}
                            />
                          </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-left ml-2 grid gap-3 capitalize">
                          <a
                            href="/behind-the-scenes"
                            className="hover:text-blue-600 "
                          >
                            Behind the scenes{" "}
                          </a>
                          <a href="/ngos" className="hover:text-blue-600">
                            Nature Organisations
                          </a>
                          <a href="/press" className="hover:text-blue-600">
                            Press Kit
                          </a>
                          <a href="/faq" className="hover:text-blue-600">
                            Frequent Questions
                          </a>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button>
                          <span className="flex gap-2 justify-between hover:text-blue-600 ">
                            Get involved
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } w-5 h-5`}
                            />
                          </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-left ml-2 grid gap-2 capitalize">
                          {/* <a href="/donate" className="hover:text-blue-600">
                            Donate
                          </a> */}
                          <a href="/contact" className="hover:text-blue-600">
                            Contact us
                          </a>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
