import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";

import press from "../../assets/media/press.webp";
import turtle from "../../assets/emojis/turtle.webp";

function PressPage() {
  const navigate = useNavigate();

  const socialLogin = () => {
    if (window.social_url) {
      window.location.href = window.social_url;
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <main className="bg-white min-h-screen">
        <Header />
        <section className="flex justify-center items-center">
          <div className="grid py-20 px-8 max-w-7xl w-full">
            <div className="flex">
              <div>
                <p className="text-4xl sm:text-6xl font-light">Spread the</p>
                <p className="text-5xl sm:text-7xl font-serif mb-4">word</p>
              </div>
              <span
                role="img"
                aria-label="emoji"
                className="text-8xl mt-8 -ml-8"
              >
                <img alt="turtle" src={turtle} className="w-24" />
              </span>
            </div>

            <div className="grid md:grid-cols-3">
              <div></div>
              <p className="col-span-2 text-2xl sm:text-4xl font-serif ">
                Download the official press kit and help us spread the word.
              </p>
            </div>
            <p className="mt-10">
              Download press kit{" "}
              <a
                href="https://www.dropbox.com/sh/qo904axqpznoo2y/AAAFV_FihuJUqLOQggJ73aW9a?dl=0"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:opacity-60 underline"
              >
                by clicking here.
              </a>
            </p>
          </div>
        </section>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "tween", duration: 1 }}
          className="rounded-xl m-4 sm:m-10 overflow-hidden"
        >
          <img src={press} className="w-full" alt="No Nature found" />
        </motion.div>
        <div className="flex flex-col h-full w-full items-center justify-end text-gray-900 mt-10">
          <p className="font-serif text-4xl sm:text-6xl p-6 text-center">
            See how much you owe Nature
          </p>
          <button
            onClick={socialLogin}
            className="text-yellow-50 text-2xl bg-gray-900 font-light py-2 px-4 rounded-full hover:bg-yellow-50 hover:text-gray-900 transition-all"
          >
            Get your Estimate
          </button>
        </div>
      </main>

      <Footer />
    </>
  );
}
export default PressPage;
