import { useState } from "react";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import whyNature from "../../assets/media/whyNature.webp";
import { motion } from "framer-motion/dist/framer-motion";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DonatePage() {
  const [agreed, setAgreed] = useState(false);

  return (
    <>
      <Header />

      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "tween", duration: 1 }}
        className="rounded-xl m-4 sm:m-10 overflow-hidden min-h-screen donateBg border"
      >
        <div className="flex items-center justify-center w-full min-h-screen bg-gradient-to-b from-transparent to-black p-6">
          <div className="flex flex-col items-center justify-center text-yellow-50">
            <p className="font-serif text-4xl sm:text-6xl p-6 text-center">
              Donate to Nature through us
            </p>
            <p className="my-4 text-lg text-center leading-6 text-yello-50 max-w-xl">
              Send us a message and we'll advise on which organisation to donate
              to based on your preferences and manage the donation process for
              you.
            </p>
            <a
              href="mailto:hi@natureinfluencer.com"
              className="text-2xl sm:text-5xl font-serif text-yellow-50 "
            >
              hi@natureinfluencer.com
            </a>
          </div>
        </div>
      </motion.div>

      <Footer />
    </>
  );
}
