import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";
import { sanitize } from 'isomorphic-dompurify'
import { useNavigate } from "react-router-dom";

import whyNature from "../../assets/media/whyNature.webp";
import globe from "../../assets/emojis/globe.webp";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const faqs = [
  {
    question: "Why Nature?",
    answer:
      "Nature is our partner in so many of the photos that gained us fame on social networks. Yet we always take it for granted - it’s time to pay it back.",
  },
  {
    question: "What's an influencer?",
    answer: `Influencers are creators, partners, instagrammers, youtubers and ultimately brand ambassadors. \n\n"They create high quality content for brands and services and have a strong and engaged community built and grown over time. The word influencer means someone who is building a platform with the intention of being used by brands for marketing purposes" says Natasha Hynes, a YouTuber who calls herself a creator.`,
  },
  {
    question: "Who are the people behind this?",
    answer:
      `With over 300 employees worldwide,  <a
      href="http://lateralgroup.com/"
      class="hover:opacity-60"
      target="_blank"
      rel="noreferrer"
    >
    Lateral Group
    </a> is a team of designers, inventors & engineers with a simple mission: to build great products.`,
  },

  // More questions...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function FAQPage() {
  const navigate = useNavigate();

  const socialLogin = () => {
    if (window.social_url) {
      window.location.href = window.social_url;
    } else {
      navigate("/login");
    }
  };


  const createMarkup = (string) => {
    return {
      __html: sanitize(string),
    }
  }

  return (
    <>
      <main className="bg-white min-h-screen">
        <Header />
        <section className="flex justify-center items-center">
          <div className="flex flex-col py-20 px-8 max-w-7xl min-w-max">
            <div className="flex ">
              <div className="z-10">
                <p className="text-4xl sm:text-6xl">Nature</p>
                <p className="text-5xl sm:text-7xl font-serif mb-4">FAQs</p>
              </div>
              <span
                role="img"
                aria-label="emoji"
                className="text-8xl mt-8 -ml-8 z-0"
              >
                <img alt="Nature" src={globe} className="w-24" />
              </span>
            </div>

            <div className="grid w-full min-w-full"></div>
          </div>
        </section>

        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-serif text-4xl text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500" dangerouslySetInnerHTML={createMarkup(faq.answer)} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "tween", duration: 1 }}
          className="rounded-xl m-4 sm:m-10 overflow-hidden relative"
        >
          <img src={whyNature} className="w-full" alt="Why Nature" />
          <div className="absolute top-0 right-0 w-full h-full bg-gradient-to-b from-transparent to-black opacity-50"></div>
          <div className="absolute top-0 right-0 w-full h-full">
            <div className="flex flex-col h-full w-full items-center justify-center text-yellow-50">
              <p className="font-serif text-4xl sm:text-6xl p-6 text-center">
                See how much you owe Nature
              </p>
              <button
                onClick={socialLogin}
                className="text-gray-900 text-2xl bg-yellow-50 font-light py-2 px-4 rounded-full hover:text-yellow-50 hover:bg-gray-900 transition-all"
              >
                Connect your account
              </button>
            </div>
          </div>
        </motion.div>
      </main>

      <Footer />
    </>
  );
}
export default FAQPage;
