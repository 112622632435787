import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion/dist/framer-motion";

import React from "react";
import noNature from "../../assets/media/noNature.webp";
import { Link } from "react-router-dom";
import herb from "../../assets/emojis/herb.webp";

function NoNature({ handle }) {
  const userHandle = handle || "instaHandle";

  return (
    <>
      <Header />

      <main className="bg-white overflow-hidden">
        <section className="flex flex-col items-center justify-center p-6 mb-16">
          <div className="flex flex-col items-center justify-center max-w-2xl text-center">
            <motion.h2
              className="mt-24 text-4xl sm:text-6xl font-light"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "tween", duration: 1 }}
            >
              @{userHandle}
            </motion.h2>
            <p className="font-serif text-3xl sm:text-4xl mt-4">
              I did not find any nature in your photos. <br />
              Maybe spend more time outside and try again.
            </p>
            <p className="ml-10 -mt-6 ">
              <img src={herb} className="w-20" />
            </p>
          </div>
          <Link to="/">
            <p className="mt-6 hover:text-gray-500 text-sm cursor-pointer">
              Go back Home
            </p>
          </Link>
        </section>

        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "tween", duration: 1 }}
          className="rounded-xl m-10 overflow-hidden"
        >
          <img src={noNature} className="w-full" alt="No Nature found" />
        </motion.div>
      </main>
      <Footer />
    </>
  );
}
export default NoNature;
