import Header from "../../components/Header";
import Footer from "../../components/Footer";
import NGOs from "../../components/NGOs";
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";

import invoiceVideo from "../../assets/media/invoiceVideo.mp4";
import backgroundIvoiceMobileVideo from "../../assets/media/backgroundInvoiceMobile.mp4";
import invoicePic from "../../assets/media/invoice.png";
import hedgehog from "../../assets/emojis/hedgehog.webp";
import desert from "../../assets/emojis/desert.webp";

export default function NGOPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();

  const socialLogin = () => {
    if (window.social_url) {
      window.location.href = window.social_url;
    } else {
      navigate("/invoice");
    }
  };
  return (
    <>
      <main className="bg-white overflow-hidden">
        <Header />
        {/* <section className="flex justify-center items-center">
          <div className="grid py-20 px-8 max-w-7xl">
            <div className="flex">
              <div>
                <p className="text-4xl sm:text-6xl font-light">Who do we</p>
                <p className="text-5xl sm:text-7xl font-serif mb-4">
                  donate to?
                </p>
              </div>
              <span
                role="img"
                aria-label="emoji"
                className="text-8xl mt-8 -ml-8"
              >
                <img src={hedgehog} className="w-24" />
              </span>
            </div>

            <div className="grid md:grid-cols-3">
              <div></div>
              <p className="col-span-2 text-2xl sm:text-4xl font-serif ">
                Your donation from your partnership with Nature will be split
                among organisations that champion causes related to what our
                algorithm identified in your pictures.
              </p>
            </div>
          </div>
        </section> */}

        <NGOs limit={300} />

        <section className="flex justify-center items-center">
          <div className="grid py-12 px-8 max-w-7xl  w-full">
            <div className="grid z-10 ">
              <div className="flex ">
                <p className="text-5xl sm:text-6xl font-light">Pay Nature</p>{" "}
                <span className="text-8xl -ml-4 -mt-8">
                  <img src={desert} className="w-24" />
                </span>
              </div>
              <p className="text-6xl sm:text-7xl font-serif  sm:mt-0 mb-6">
                what Nature is owed
              </p>
            </div>
          </div>
        </section>

        <section className="relative flex items-center justify-center m-6 py-12 rounded-xl text-white invoice-bg bg-cover bg-right-bottom">
          <div className="flex flex-col items-center justify-center z-30 ">
            <motion.img
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 1, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                type: "spring",
                bounce: 0.5,
                duration: 2,
                delay: 0,
              }}
              src={invoicePic}
              className="-ml-16 sm:ml-20 z-50 mt-10 sm:-mt-32 max-w-xs sm:max-w-2xl"
            />
            <p className="p-4 text-4xl sm:text-6xl font-light text-center">
              What&#39;s your <br />
              <span className="font-serif text-5xl sm:text-7xl">
                fair share?
              </span>
            </p>
            <button
              onClick={socialLogin}
              className="bg-gray-900 text-2xl font-light py-2 px-4 rounded-full mt-3 hover:bg-yellow-50 hover:text-gray-900 transition-all"
            >
              Get your Estimate
            </button>
          </div>
          <div className="flex items-center justify-center min-w-full max-h-full max-w-none absolute z-10 sm:m-6 rounded-xl overflow-hidden">
            <video
              autoPlay
              loop
              muted
              playsInline
              defaultMuted
              className="w-auto min-w-full max-h-full min-h-full hidden sm:block"
            >
              <source src={invoiceVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <video
              autoPlay
              loop
              muted
              playsInline
              defaultMuted
              className="w-auto min-w-full min-h-screen sm:hidden"
            >
              <source src={backgroundIvoiceMobileVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
