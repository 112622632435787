import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion/dist/framer-motion";
import CountUp from "react-countup";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";
import { Link } from "react-router-dom";

function ThankyouPage() {
  //Takes the photoArray from localStorage to show the panels for Instagram sharing
  const photoArray = JSON.parse(localStorage.getItem("photoArray") || "[]");
  localStorage.setItem("photoArray", "[]");

  const donationAmount = window.donation_amount || 0;
  const userHandle = window.username || "instaHandle";

  return (
    <>
      <main className="bg-white min-h-screen">
        <Header />
        <section className="flex flex-col items-center justify-center p-6 ">
          <div className="max-w-2xl text-center flex flex-col items-center ">
            <motion.div
              className=" max-w-max text-yellow-50"
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 50, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                type: "spring",
                bounce: 0.5,
                duration: 1,
                delay: 0.4,
              }}
            >
              <p className="py-2 px-3 bg-gray-800 rounded-lg text-sm">
                <span className="text-green-500 mr-2 animate-pulse">•</span>{" "}
                Payment: Completed
              </p>
            </motion.div>

            <motion.h2
              className="mt-24 text-5xl  font-light"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "tween", duration: 1 }}
            >
              Thank you @{userHandle}!
            </motion.h2>
            <p className="text-5xl font-serif">for supporting Nature with</p>
            <p className="font-druk text-8xl font-black mt-4">
              $<CountUp end={donationAmount / 100} duration={3} />
            </p>
            <p className="text-sm mt-10">
              All profits will go to NGO&#39;s supporting nature. You can read
              more{" "}
              <Link to="/ngos" className="underline hover:text-gray-500">
                here
              </Link>
              . You will receive an email invoice for your donation shortly.
            </p>
          </div>
        </section>

        <section className="text-center p-10">
          <p className="font-serif text-4xl mb-3">
            Share this momentous achievement with your friends!
          </p>
          <div className="flex items-center justify-center gap-3 ">
            <div className="flex items-center p-2 border rounded-full hover:bg-yellow-50">
              <FacebookShareButton
                url="https://natureinfluencer.com"
                size={32}
                quote={`I just donated to Nature!`}
                hashtag="unpaidPartnershipWithNature"
                className="p-2 border rounded-full hover:bg-white "
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
            </div>
            <div className="flex items-center p-2 border rounded-full hover:bg-yellow-50">
              <TwitterShareButton
                url="https://natureinfluencer.com"
                size={32}
                title={`I just donated to Nature!`}
                hashtag="unpaidPartnershipWithNature"
                className="p-2 border rounded-full hover:bg-white "
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
            </div>
            {/* <div className="flex items-center p-2 border rounded-full hover:bg-yellow-50">
              <LinkedinShareButton
                url="https://natureinfluencer.com"
                size={32}
                title={`I just donated to Nature!`}
                hashtag="unpaidPartnershipWithNature"
                className="p-2 border rounded-full hover:bg-white "
              >
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
            </div>
            <div className="flex items-center p-2 border rounded-full hover:bg-yellow-50">
              <TelegramShareButton
                url="https://natureinfluencer.com"
                size={32}
                title={`I just donated to Nature!`}
                hashtag="unpaidPartnershipWithNature"
                className="p-2 border rounded-full hover:bg-white "
              >
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>
            </div> */}
            <div className="flex items-center p-2 border rounded-full hover:bg-yellow-50">
              <WhatsappShareButton
                url="https://natureinfluencer.com"
                size={32}
                title={`I just donated to Nature!`}
                hashtag="unpaidPartnershipWithNature"
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </div>
          </div>
        </section>

        <section className="flex flex-col items-center justify-center p-6 ">
          <div className="flex gap-2 mb-4">
            <p className="text-center">Screenshot and share on your Insta</p>{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              x="0"
              y="0"
              viewBox="0 0 16 16"
            >
              <path d="M1.5 1c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm2 0c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm2 0c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm2 0c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm2 0c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm2 0c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm-10 2c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm10 0c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm-10 2c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm7.066 0c-.394 0-.847.219-1.035.695L7.414 6H6.191C5.496 6 5 6.625 5 7.3v5.4c0 .675.496 1.3 1.191 1.3h7.618C14.5 14 15 13.375 15 12.7V7.3c0-.675-.496-1.3-1.191-1.3h-1.22l-.124-.313v.008a1.086 1.086 0 00-.781-.664s0 .004 0 0A.584.584 0 0011.5 5H11.445zm0 1h2.88c.03 0 .066 0 .09.055v.004L11.91 7h1.899c.07 0 .191.09.191.297v5.402c0 .207-.125.301-.191.301H6.19c-.07 0-.191-.094-.191-.3V7.3c0-.206.121-.3.191-.3h1.895l.379-.941.004-.004c.011-.04.07-.055.097-.055zM1.5 7c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zM10 8c-1.098 0-2 .902-2 2 0 1.098.902 2 2 2 1.098 0 2-.902 2-2 0-1.098-.902-2-2-2zM1.5 9c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zM10 9c.559 0 1 .441 1 1 0 .559-.441 1-1 1-.559 0-1-.441-1-1 0-.559.441-1 1-1zm-8.5 2c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm2 0c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5z"></path>
            </svg>
          </div>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "tween", duration: 1 }}
            className="flex items-center justify-center text-center gap-4 flex-wrap"
          >
            {/* <div className=" relative h-[600px] w-[350px] rounded-xl bg-gray-200  overflow-hidden">
              <div className="absolute top-0 left-0 z-50 min-w-full h-[600px]">
                <div className="flex flex-col items-center justify-center p-4  h-full min-h-max ">
                  <img
                    alt="NatureIcon"
                    src={globe}
                    className="w-10 h-10 hover:opacity-75 cursor-pointer ml-1 transition-all mb-4"
                  />

                  <p className="text-yellow-50 font-serif text-3xl">
                    {userHandle || "@instaHandle"} donated to nature!
                  </p>
                </div>
              </div>
              <img
                src="https://images.unsplash.com/photo-1469474968028-56623f02e42e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2348&q=80"
                alt=""
                className="absolute top-0 left-0 w-full h-full object-cover z-0"
              />
            </div> */}

            <div className=" relative h-[600px] w-[350px] rounded-xl bg-green-900  overflow-hidden">
              <div className="flex flex-col items-center justify-center p-4  h-full min-h-max ">
                <p className="text-green-200 font-serif text-3xl">
                  @{userHandle || "@instaHandle"}
                </p>
                <p className="text-green-200 text-xl">donated to nature!</p>
                <img
                  className="w-full h-60 object-cover rounded-xl -rotate-3 mt-6"
                  src={photoArray[0].src}
                  alt=""
                />
                <p className="text-green-200 mt-10">#PartnershipWithNature</p>
                <p className="text-green-400">NatureInfluencer.com</p>
              </div>
            </div>

            <div className=" relative h-[600px] w-[350px] rounded-xl bg-blue-900  overflow-hidden">
              <div className="flex flex-col items-center justify-center p-4  h-full min-h-max ">
                <p className="text-blue-200 font-serif text-3xl">
                  My go to is the #{photoArray[1].tag}
                </p>
                <img
                  className="w-full h-60 object-cover rounded-xl rotate-3 mt-6"
                  src={photoArray[1].src}
                  alt=""
                />
                <p className="text-blue-200 mt-10">#PartnershipWithNature</p>
                <p className="text-blue-400">NatureInfluencer.com</p>
              </div>
            </div>

            <div className=" relative h-[600px] w-[350px] rounded-xl bg-green-900  overflow-hidden">
              <div className="flex flex-col items-center justify-center p-4  h-full min-h-max ">
                <p className="text-green-200 font-serif text-3xl">
                  Nature was my partner #{photoArray[2].tag}
                  <img
                    className="w-full h-60 object-cover rounded-xl -rotate-3 mt-4"
                    src={photoArray[2].src}
                    alt=""
                  />
                </p>
                <p className="text-green-200 mt-10">#PartnershipWithNature</p>
                <p className="text-green-400">NatureInfluencer.com</p>
              </div>
            </div>

            <div className=" relative h-[600px] w-[350px] rounded-xl bg-yellow-900  overflow-hidden">
              <div className="flex flex-col items-center justify-center p-4  h-full min-h-max ">
                <p className="text-yellow-200 font-serif text-3xl">
                  Nature has an unconventional fundraising campaign
                  <img
                    className="w-full h-60 object-cover rounded-xl rotate-3 mt-4"
                    src={photoArray[3].src}
                    alt=""
                  />
                </p>
                <p className="text-yellow-200 mt-10">#PartnershipWithNature</p>
                <p className="text-yellow-400">NatureInfluencer.com</p>
              </div>
            </div>

            {/* <div className=" relative h-[600px] w-[350px] rounded-xl bg-gray-200  overflow-hidden">
              <div className="absolute top-0 left-0 z-50 min-w-full h-[600px]">
                <div className="flex flex-col items-center justify-center p-4  h-full min-h-max ">
                  <img
                    alt="NatureIcon"
                    src={globe}
                    className="w-10 h-10 hover:opacity-75 cursor-pointer ml-1 transition-all"
                  />

                  <p className="text-yellow-50 font-serif text-3xl mt-4">
                    Find out <br />
                    how much you owe!
                  </p>
                  <p className="text-yellow-50 mt-4">
                    Go to NatureInfluencer.com <br />
                    and Get your Estimate
                  </p>
                </div>
              </div>
              <img
                src="https://images.unsplash.com/photo-1470770903676-69b98201ea1c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
                alt=""
                className="absolute top-0 left-0 w-full h-full object-cover z-0"
              />
              <div className="absolute bg-blue-700 opacity-60 top-0 left-0 w-full h-full z-10"></div>
            </div> */}
          </motion.div>
        </section>
      </main>

      <Footer />
    </>
  );
}
export default ThankyouPage;
