import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";

import press from "../../assets/media/press.webp";

function PrivacyPage() {
  const navigate = useNavigate();
  const socialLogin = () => {
    if (window.social_url) {
      window.location.href = window.social_url;
    } else {
      navigate("/invoice");
    }
  };
  return (
    <>
      <main className="bg-white min-h-screen">
        <Header />
        <section className="flex justify-center items-center">
          <div className="grid py-20 px-8 max-w-7xl w-full gap-4">
            <div className="">
              <div className="flex gap-2">
                <p className="text-4xl sm:text-6xl font-light">Privacy</p>
                <p className="text-5xl sm:text-7xl font-serif mb-4 -mt-1">
                  policy
                </p>
              </div>
            </div>

            <div>
              <strong>
                <span data-custom-class="subtitle">
                  Last updated&nbsp;July 19, 2022
                </span>
              </strong>
            </div>
            <div>
              <span data-custom-class="body_text">
                This privacy notice for&nbsp;Lateral Group&nbsp;("
                <strong>Company</strong>," "<strong>we</strong>," "
                <strong>us</strong>," or "<strong>our</strong>"
              </span>
              <span data-custom-class="body_text">
                ), describes how and why we might collect, store, use, and/or
                share ("<strong>process</strong>") your information when you use
                our services ("<strong>Services</strong>"), such as when you:
              </span>
            </div>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  Visit our website&nbsp;at&nbsp;
                  <a
                    href="https://natureinfluencer.com/"
                    target="_blank"
                    data-custom-class="link"
                  >
                    https://natureinfluencer.com/
                  </a>
                  <span data-custom-class="body_text">
                    , or any website of ours that links to this privacy notice
                  </span>
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  Download and use&nbsp;our Facebook application&nbsp;(Nature
                  Influencer),&nbsp;or any other application of ours that links
                  to this privacy notice
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </span>
              </li>
            </ul>
            <div>
              <span data-custom-class="body_text">
                <strong>Questions or concerns?&nbsp;</strong>Reading this
                privacy notice will help you understand your privacy rights and
                choices. If you do not agree with our policies and practices,
                please do not use our Services. If you still have any questions
                or concerns, please contact us at&nbsp;hello@lateralgroup.com.
              </span>
            </div>
            <div>
              <strong>
                <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
              </strong>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>
                  <em>
                    This summary provides key points from our privacy notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our table of contents below to find the section you are
                    looking for. You can also click&nbsp;
                  </em>
                </strong>
              </span>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#toc"
                data-custom-class="link"
              >
                <span data-custom-class="body_text">
                  <strong>
                    <em>here</em>
                  </strong>
                </span>
              </a>
              <span data-custom-class="body_text">
                <strong>
                  <em>&nbsp;to go directly to our table of contents.</em>
                </strong>
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>What personal information do we process?</strong>
                &nbsp;When you visit, use, or navigate our Services, we may
                process personal information depending on how you interact
                with&nbsp;Lateral Group&nbsp;and the Services, the choices you
                make, and the products and features you use. Click&nbsp;
              </span>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#personalinfo"
                data-custom-class="link"
              >
                <span data-custom-class="body_text">here</span>
              </a>
              <span data-custom-class="body_text">&nbsp;to learn more.</span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>
                  Do we process any sensitive personal information?
                </strong>
                &nbsp;We do not process sensitive personal information.
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>
                  Do we receive any information from third parties?
                </strong>
                &nbsp;We do not receive any information from third parties.
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>How do we process your information?</strong>&nbsp;We
                process your information to provide, improve, and administer our
                Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Click&nbsp;
              </span>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#infouse"
                data-custom-class="link"
              >
                <span data-custom-class="body_text">here</span>
              </a>
              <span data-custom-class="body_text">&nbsp;to learn more.</span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>
                  In what situations and with which&nbsp;parties do we share
                  personal information?
                </strong>
                &nbsp;We may share information in specific situations and with
                specific&nbsp;third parties. Click&nbsp;
              </span>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#whoshare"
                data-custom-class="link"
              >
                <span data-custom-class="body_text">here</span>
              </a>
              <span data-custom-class="body_text">&nbsp;to learn more.</span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>How do we keep your information safe?</strong>&nbsp;We
                have&nbsp;organizational&nbsp;and technical processes and
                procedures in place to protect your personal information.
                However, no electronic transmission over the internet or
                information storage technology can be guaranteed to be 100%
                secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other&nbsp;unauthorized&nbsp;third parties
                will not be able to defeat our security and improperly collect,
                access, steal, or modify your information. Click&nbsp;
              </span>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#infosafe"
                data-custom-class="link"
              >
                <span data-custom-class="body_text">here</span>
              </a>
              <span data-custom-class="body_text">&nbsp;to learn more.</span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>What are your rights?</strong>&nbsp;Depending on where
                you are located geographically, the applicable privacy law may
                mean you have certain rights regarding your personal
                information. Click&nbsp;
              </span>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#privacyrights"
                data-custom-class="link"
              >
                <span data-custom-class="body_text">here</span>
              </a>
              <span data-custom-class="body_text">&nbsp;to learn more.</span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>How do you exercise your rights?</strong>&nbsp;The
                easiest way to exercise your rights is by filling out our data
                subject request form available&nbsp;here:&nbsp;
                <a
                  href="https://natureinfluencer.com/privacy"
                  target="_blank"
                  data-custom-class="link"
                >
                  https://natureinfluencer.com/privacy
                </a>
                , or by contacting us. We will consider and act upon any request
                in accordance with applicable data protection laws.
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                Want to learn more about what&nbsp;Lateral Group&nbsp;does with
                any information we collect? Click&nbsp;
              </span>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#toc"
                data-custom-class="link"
              >
                <span data-custom-class="body_text">here</span>
              </a>
              <span data-custom-class="body_text">
                &nbsp;to review the notice in full.
              </span>
            </div>
            <div>
              <strong>
                <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
              </strong>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#infocollect"
                data-custom-class="link"
              >
                1. WHAT INFORMATION DO WE COLLECT?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#infouse"
                data-custom-class="link"
              >
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#legalbases"
                data-custom-class="link"
              >
                3.&nbsp;WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#whoshare"
                data-custom-class="link"
              >
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#cookies"
                data-custom-class="link"
              >
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#sociallogins"
                data-custom-class="link"
              >
                6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#inforetain"
                data-custom-class="link"
              >
                7. HOW LONG DO WE KEEP YOUR INFORMATION?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#infosafe"
                data-custom-class="link"
              >
                8. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#infominors"
                data-custom-class="link"
              >
                9. DO WE COLLECT INFORMATION FROM MINORS?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#privacyrights"
                data-custom-class="link"
              >
                10. WHAT ARE YOUR PRIVACY RIGHTS?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#DNT"
                data-custom-class="link"
              >
                11. CONTROLS FOR DO-NOT-TRACK FEATURES
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#caresidents"
                data-custom-class="link"
              >
                12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#policyupdates"
                data-custom-class="link"
              >
                13. DO WE MAKE UPDATES TO THIS NOTICE?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#contact"
                data-custom-class="link"
              >
                14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
            </div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#request"
                data-custom-class="link"
              >
                15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </a>
            </div>
            <div>
              <strong>
                <span data-custom-class="heading_1">
                  1. WHAT INFORMATION DO WE COLLECT?
                </span>
              </strong>
            </div>
            <div>
              <span data-custom-class="heading_2">
                <strong>Personal information you disclose to us</strong>
              </span>
            </div>
            <div>
              <div>
                <span data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short:</em>
                    </strong>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    <em>
                      We collect personal information that you provide to us.
                    </em>
                  </span>
                </span>
              </div>
            </div>
            <div>
              <span data-custom-class="body_text">
                We collect personal information that you voluntarily provide to
                us when you&nbsp;register on the Services,&nbsp;
              </span>
              <span data-custom-class="body_text">
                express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the
                Services, or otherwise when you contact us.
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>Personal Information Provided by You.</strong>&nbsp;The
                personal information that we collect depends on the context of
                your interactions with us and the Services, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:
              </span>
            </div>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  <span data-custom-class="body_text">instagram handles</span>
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  <span data-custom-class="body_text">media</span>
                </span>
              </li>
            </ul>
            <div>
              <span data-custom-class="body_text">
                <strong>Sensitive Information.</strong>&nbsp;We do not process
                sensitive information.
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                <strong>Social Media Login Data.&nbsp;</strong>We may provide
                you with the option to register with us using your existing
                social media account details, like your Facebook, Twitter, or
                other social media account. If you choose to register in this
                way, we will collect the information described in the section
                called&nbsp;"
                <span data-custom-class="body_text">
                  <a
                    href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#sociallogins"
                    data-custom-class="link"
                  >
                    HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                  </a>
                </span>
                "&nbsp;below.
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </span>
            </div>
            <div>
              <span data-custom-class="heading_2">
                <strong>Information automatically collected</strong>
              </span>
            </div>
            <div>
              <div>
                <span data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short:</em>
                    </strong>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span data-custom-class="body_text">
                    <em>
                      Some information &mdash; such as your Internet Protocol
                      (IP) address and/or browser and device characteristics
                      &mdash; is collected automatically when you visit our
                      Services.
                    </em>
                  </span>
                </span>
              </div>
            </div>
            <div>
              <span data-custom-class="body_text">
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </span>
            </div>
            <div>
              <span data-custom-class="body_text">
                The information we collect includes:
              </span>
            </div>
            <ul>
              <li>
                <span data-custom-class="body_text">
                  <em>Log and Usage Data.</em>&nbsp;Log and usage data is
                  service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access
                  or use our Services and which we record in log files.
                  Depending on how you interact with us, this log data may
                  include your IP address, device information, browser type, and
                  settings and information about your activity in the
                  Services&nbsp;(such as the date/time stamps associated with
                  your usage, pages and files viewed, searches, and other
                  actions you take such as which features you use), device event
                  information (such as system activity, error reports (sometimes
                  called&nbsp;"crash dumps"), and hardware settings).
                </span>
              </li>
            </ul>
            <div>
              <div>
                <div>
                  <span data-custom-class="body_text">
                    <strong>
                      Information collected when you use our Facebook
                      application(s).
                    </strong>
                    &nbsp;We by default access your&nbsp;
                  </span>
                  <a
                    href="https://www.facebook.com/about/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-custom-class="link"
                  >
                    <span data-custom-class="body_text">Facebook</span>
                  </a>
                  <span data-custom-class="body_text">
                    &nbsp;basic account information, including your name, email,
                    gender, birthday, current city, and profile picture URL, as
                    well as other information that you choose to make public. We
                    may also request access to other permissions related to your
                    account, such as friends, check-ins, and likes, and you may
                    choose to grant or deny us access to each individual
                    permission. For more information regarding Facebook
                    permissions, refer to the&nbsp;
                  </span>
                  <a
                    href="https://developers.facebook.com/docs/facebook-login/permissions"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-custom-class="link"
                  >
                    <span data-custom-class="body_text">
                      Facebook Permissions Reference
                    </span>
                  </a>
                  <span data-custom-class="body_text">&nbsp;page.</span>
                </div>
                <div>
                  <strong>
                    <span data-custom-class="heading_1">
                      2. HOW DO WE PROCESS YOUR INFORMATION?
                    </span>
                  </strong>
                </div>
                <div>
                  <div>
                    <span data-custom-class="body_text">
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:&nbsp;</em>
                        </strong>
                        <em>
                          We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.
                        </em>
                      </span>
                    </span>
                  </div>
                </div>
                <div>
                  <span data-custom-class="body_text">
                    <strong>
                      We process your personal information for a variety of
                      reasons, depending on how you interact with our Services,
                      including:
                    </strong>
                  </span>
                </div>
                <ul>
                  <li>
                    <span data-custom-class="body_text">
                      <strong>
                        To facilitate account creation and authentication and
                        otherwise manage user accounts.&nbsp;
                      </strong>
                      We may process your information so you can create and log
                      in to your account, as well as keep your account in
                      working order.
                    </span>
                  </li>
                </ul>
                <div>
                  <div>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <div>
                                <div>
                                  <div>
                                    <div>
                                      <div>
                                        <div>
                                          <div>
                                            <div>
                                              <div>
                                                <div>
                                                  <div>
                                                    <div>
                                                      <div>
                                                        <div>
                                                          <div>
                                                            <ul>
                                                              <li>
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    To save or
                                                                    protect an
                                                                    individual's
                                                                    vital
                                                                    interest.
                                                                  </strong>
                                                                  &nbsp;We may
                                                                  process your
                                                                  information
                                                                  when necessary
                                                                  to save or
                                                                  protect an
                                                                  individual&rsquo;s
                                                                  vital
                                                                  interest, such
                                                                  as to prevent
                                                                  harm.
                                                                </span>
                                                              </li>
                                                            </ul>
                                                            <div>
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  3. WHAT LEGAL
                                                                  BASES DO WE
                                                                  RELY ON TO
                                                                  PROCESS YOUR
                                                                  INFORMATION?
                                                                </span>
                                                              </strong>
                                                            </div>
                                                            <div>
                                                              <em>
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    In
                                                                    Short:&nbsp;
                                                                  </strong>
                                                                  We only
                                                                  process your
                                                                  personal
                                                                  information
                                                                  when we
                                                                  believe it is
                                                                  necessary and
                                                                  we have a
                                                                  valid legal
                                                                  reason
                                                                  (i.e.,&nbsp;legal
                                                                  basis) to do
                                                                  so under
                                                                  applicable
                                                                  law, like with
                                                                  your consent,
                                                                  to comply with
                                                                  laws, to
                                                                  provide you
                                                                  with services
                                                                  to enter into
                                                                  or&nbsp;fulfill&nbsp;our
                                                                  contractual
                                                                  obligations,
                                                                  to protect
                                                                  your rights,
                                                                  or
                                                                  to&nbsp;fulfill&nbsp;our
                                                                  legitimate
                                                                  business
                                                                  interests.
                                                                </span>
                                                              </em>
                                                            </div>
                                                            <div>
                                                              <em>
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <u>
                                                                      If you are
                                                                      located in
                                                                      the EU or
                                                                      UK, this
                                                                      section
                                                                      applies to
                                                                      you.
                                                                    </u>
                                                                  </strong>
                                                                </span>
                                                              </em>
                                                            </div>
                                                            <div>
                                                              <span data-custom-class="body_text">
                                                                The General Data
                                                                Protection
                                                                Regulation
                                                                (GDPR) and UK
                                                                GDPR require us
                                                                to explain the
                                                                valid legal
                                                                bases we rely on
                                                                in order to
                                                                process your
                                                                personal
                                                                information. As
                                                                such, we may
                                                                rely on the
                                                                following legal
                                                                bases to process
                                                                your personal
                                                                information:
                                                              </span>
                                                            </div>
                                                            <ul>
                                                              <li>
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    Consent.&nbsp;
                                                                  </strong>
                                                                  We may process
                                                                  your
                                                                  information if
                                                                  you have given
                                                                  us permission
                                                                  (i.e.,&nbsp;consent)
                                                                  to use your
                                                                  personal
                                                                  information
                                                                  for a specific
                                                                  purpose. You
                                                                  can withdraw
                                                                  your consent
                                                                  at any time.
                                                                  Click&nbsp;
                                                                </span>
                                                                <a
                                                                  href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#withdrawconsent"
                                                                  data-custom-class="link"
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    here
                                                                  </span>
                                                                </a>
                                                                <span data-custom-class="body_text">
                                                                  &nbsp;to learn
                                                                  more.
                                                                </span>
                                                              </li>
                                                            </ul>
                                                            <div>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      Legal
                                                                      Obligations.
                                                                    </strong>
                                                                    &nbsp;We may
                                                                    process your
                                                                    information
                                                                    where we
                                                                    believe it
                                                                    is necessary
                                                                    for
                                                                    compliance
                                                                    with our
                                                                    legal
                                                                    obligations,
                                                                    such as to
                                                                    cooperate
                                                                    with a law
                                                                    enforcement
                                                                    body or
                                                                    regulatory
                                                                    agency,
                                                                    exercise or
                                                                    defend our
                                                                    legal
                                                                    rights, or
                                                                    disclose
                                                                    your
                                                                    information
                                                                    as evidence
                                                                    in
                                                                    litigation
                                                                    in which we
                                                                    are
                                                                    involved.
                                                                    <br />
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      Vital
                                                                      Interests.
                                                                    </strong>
                                                                    &nbsp;We may
                                                                    process your
                                                                    information
                                                                    where we
                                                                    believe it
                                                                    is necessary
                                                                    to protect
                                                                    your vital
                                                                    interests or
                                                                    the vital
                                                                    interests of
                                                                    a third
                                                                    party, such
                                                                    as
                                                                    situations
                                                                    involving
                                                                    potential
                                                                    threats to
                                                                    the safety
                                                                    of any
                                                                    person.
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <u>
                                                                      <em>
                                                                        If you
                                                                        are
                                                                        located
                                                                        in
                                                                        Canada,
                                                                        this
                                                                        section
                                                                        applies
                                                                        to you.
                                                                      </em>
                                                                    </u>
                                                                  </strong>
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <span data-custom-class="body_text">
                                                                  We may process
                                                                  your
                                                                  information if
                                                                  you have given
                                                                  us specific
                                                                  permission
                                                                  (i.e.,&nbsp;express
                                                                  consent) to
                                                                  use your
                                                                  personal
                                                                  information
                                                                  for a specific
                                                                  purpose, or in
                                                                  situations
                                                                  where your
                                                                  permission can
                                                                  be inferred
                                                                  (i.e.,&nbsp;implied
                                                                  consent). You
                                                                  can withdraw
                                                                  your consent
                                                                  at any time.
                                                                  Click&nbsp;
                                                                </span>
                                                                <a
                                                                  href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#withdrawconsent"
                                                                  data-custom-class="link"
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    here
                                                                  </span>
                                                                </a>
                                                                <span data-custom-class="body_text">
                                                                  &nbsp;to learn
                                                                  more.
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <span data-custom-class="body_text">
                                                                  In some
                                                                  exceptional
                                                                  cases, we may
                                                                  be legally
                                                                  permitted
                                                                  under
                                                                  applicable law
                                                                  to process
                                                                  your
                                                                  information
                                                                  without your
                                                                  consent,
                                                                  including, for
                                                                  example:
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    If
                                                                    collection
                                                                    is clearly
                                                                    in the
                                                                    interests of
                                                                    an
                                                                    individual
                                                                    and consent
                                                                    cannot be
                                                                    obtained in
                                                                    a timely way
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    For
                                                                    investigations
                                                                    and fraud
                                                                    detection
                                                                    and
                                                                    prevention
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    For business
                                                                    transactions
                                                                    provided
                                                                    certain
                                                                    conditions
                                                                    are met
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    If it is
                                                                    contained in
                                                                    a witness
                                                                    statement
                                                                    and the
                                                                    collection
                                                                    is necessary
                                                                    to assess,
                                                                    process, or
                                                                    settle an
                                                                    insurance
                                                                    claim
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    For
                                                                    identifying
                                                                    injured,
                                                                    ill, or
                                                                    deceased
                                                                    persons and
                                                                    communicating
                                                                    with next of
                                                                    kin
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    If we have
                                                                    reasonable
                                                                    grounds to
                                                                    believe an
                                                                    individual
                                                                    has been,
                                                                    is, or may
                                                                    be victim of
                                                                    financial
                                                                    abuse
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    If it is
                                                                    reasonable
                                                                    to expect
                                                                    collection
                                                                    and use with
                                                                    consent
                                                                    would
                                                                    compromise
                                                                    the
                                                                    availability
                                                                    or the
                                                                    accuracy of
                                                                    the
                                                                    information
                                                                    and the
                                                                    collection
                                                                    is
                                                                    reasonable
                                                                    for purposes
                                                                    related to
                                                                    investigating
                                                                    a breach of
                                                                    an agreement
                                                                    or a
                                                                    contravention
                                                                    of the laws
                                                                    of Canada or
                                                                    a province
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    If
                                                                    disclosure
                                                                    is required
                                                                    to comply
                                                                    with a
                                                                    subpoena,
                                                                    warrant,
                                                                    court order,
                                                                    or rules of
                                                                    the court
                                                                    relating to
                                                                    the
                                                                    production
                                                                    of records
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    If it was
                                                                    produced by
                                                                    an
                                                                    individual
                                                                    in the
                                                                    course of
                                                                    their
                                                                    employment,
                                                                    business, or
                                                                    profession
                                                                    and the
                                                                    collection
                                                                    is
                                                                    consistent
                                                                    with the
                                                                    purposes for
                                                                    which the
                                                                    information
                                                                    was produced
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    If the
                                                                    collection
                                                                    is solely
                                                                    for
                                                                    journalistic,
                                                                    artistic, or
                                                                    literary
                                                                    purposes
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    If the
                                                                    information
                                                                    is publicly
                                                                    available
                                                                    and is
                                                                    specified by
                                                                    the
                                                                    regulations
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    4. WHEN AND
                                                                    WITH WHOM DO
                                                                    WE SHARE
                                                                    YOUR
                                                                    PERSONAL
                                                                    INFORMATION?
                                                                  </span>
                                                                </strong>
                                                              </div>
                                                              <div>
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;We may
                                                                    share
                                                                    information
                                                                    in specific
                                                                    situations
                                                                    described in
                                                                    this section
                                                                    and/or with
                                                                    the
                                                                    following&nbsp;third
                                                                    parties.
                                                                  </em>
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <span data-custom-class="body_text">
                                                                  We&nbsp;may
                                                                  need to share
                                                                  your personal
                                                                  information in
                                                                  the following
                                                                  situations:
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li>
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      Business
                                                                      Transfers.
                                                                    </strong>
                                                                    &nbsp;We may
                                                                    share or
                                                                    transfer
                                                                    your
                                                                    information
                                                                    in
                                                                    connection
                                                                    with, or
                                                                    during
                                                                    negotiations
                                                                    of, any
                                                                    merger, sale
                                                                    of company
                                                                    assets,
                                                                    financing,
                                                                    or
                                                                    acquisition
                                                                    of all or a
                                                                    portion of
                                                                    our business
                                                                    to another
                                                                    company.
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <div>
                                                                  <div>
                                                                    <div>
                                                                      <div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              5.
                                                                              DO
                                                                              WE
                                                                              USE
                                                                              COOKIES
                                                                              AND
                                                                              OTHER
                                                                              TRACKING
                                                                              TECHNOLOGIES?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              <em>
                                                                                In
                                                                                Short:
                                                                              </em>
                                                                            </strong>
                                                                            <em>
                                                                              &nbsp;We
                                                                              may
                                                                              use
                                                                              cookies
                                                                              and
                                                                              other
                                                                              tracking
                                                                              technologies
                                                                              to
                                                                              collect
                                                                              and
                                                                              store
                                                                              your
                                                                              information.
                                                                            </em>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            may
                                                                            use
                                                                            cookies
                                                                            and
                                                                            similar
                                                                            tracking
                                                                            technologies
                                                                            (like
                                                                            web
                                                                            beacons
                                                                            and
                                                                            pixels)
                                                                            to
                                                                            access
                                                                            or
                                                                            store
                                                                            information.
                                                                            Specific
                                                                            information
                                                                            about
                                                                            how
                                                                            we
                                                                            use
                                                                            such
                                                                            technologies
                                                                            and
                                                                            how
                                                                            you
                                                                            can
                                                                            refuse
                                                                            certain
                                                                            cookies
                                                                            is
                                                                            set
                                                                            out
                                                                            in
                                                                            our
                                                                            Cookie
                                                                            Notice
                                                                            <span data-custom-class="body_text">
                                                                              .
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              6.
                                                                              HOW
                                                                              DO
                                                                              WE
                                                                              HANDLE
                                                                              YOUR
                                                                              SOCIAL
                                                                              LOGINS?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              <em>
                                                                                In
                                                                                Short:&nbsp;
                                                                              </em>
                                                                            </strong>
                                                                            <em>
                                                                              If
                                                                              you
                                                                              choose
                                                                              to
                                                                              register
                                                                              or
                                                                              log
                                                                              in
                                                                              to
                                                                              our
                                                                              services
                                                                              using
                                                                              a
                                                                              social
                                                                              media
                                                                              account,
                                                                              we
                                                                              may
                                                                              have
                                                                              access
                                                                              to
                                                                              certain
                                                                              information
                                                                              about
                                                                              you.
                                                                            </em>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            Our
                                                                            Services
                                                                            offer
                                                                            you
                                                                            the
                                                                            ability
                                                                            to
                                                                            register
                                                                            and
                                                                            log
                                                                            in
                                                                            using
                                                                            your
                                                                            third-party
                                                                            social
                                                                            media
                                                                            account
                                                                            details
                                                                            (like
                                                                            your
                                                                            Facebook
                                                                            or
                                                                            Twitter
                                                                            logins).
                                                                            Where
                                                                            you
                                                                            choose
                                                                            to
                                                                            do
                                                                            this,
                                                                            we
                                                                            will
                                                                            receive
                                                                            certain
                                                                            profile
                                                                            information
                                                                            about
                                                                            you
                                                                            from
                                                                            your
                                                                            social
                                                                            media
                                                                            provider.
                                                                            The
                                                                            profile
                                                                            information
                                                                            we
                                                                            receive
                                                                            may
                                                                            vary
                                                                            depending
                                                                            on
                                                                            the
                                                                            social
                                                                            media
                                                                            provider
                                                                            concerned,
                                                                            but
                                                                            will
                                                                            often
                                                                            include
                                                                            your
                                                                            name,
                                                                            email
                                                                            address,
                                                                            friends
                                                                            list,
                                                                            and
                                                                            profile
                                                                            picture,
                                                                            as
                                                                            well
                                                                            as
                                                                            other
                                                                            information
                                                                            you
                                                                            choose
                                                                            to
                                                                            make
                                                                            public
                                                                            on
                                                                            such
                                                                            a
                                                                            social
                                                                            media
                                                                            platform.&nbsp;If
                                                                            you
                                                                            log
                                                                            in
                                                                            using
                                                                            Facebook,
                                                                            we
                                                                            may
                                                                            also
                                                                            request
                                                                            access
                                                                            to
                                                                            other
                                                                            permissions
                                                                            related
                                                                            to
                                                                            your
                                                                            account,
                                                                            such
                                                                            as
                                                                            your
                                                                            friends,
                                                                            check-ins,
                                                                            and
                                                                            likes,
                                                                            and
                                                                            you
                                                                            may
                                                                            choose
                                                                            to
                                                                            grant
                                                                            or
                                                                            deny
                                                                            us
                                                                            access
                                                                            to
                                                                            each
                                                                            individual
                                                                            permission.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            will
                                                                            use
                                                                            the
                                                                            information
                                                                            we
                                                                            receive
                                                                            only
                                                                            for
                                                                            the
                                                                            purposes
                                                                            that
                                                                            are
                                                                            described
                                                                            in
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            or
                                                                            that
                                                                            are
                                                                            otherwise
                                                                            made
                                                                            clear
                                                                            to
                                                                            you
                                                                            on
                                                                            the
                                                                            relevant
                                                                            Services.
                                                                            Please
                                                                            note
                                                                            that
                                                                            we
                                                                            do
                                                                            not
                                                                            control,
                                                                            and
                                                                            are
                                                                            not
                                                                            responsible
                                                                            for,
                                                                            other
                                                                            uses
                                                                            of
                                                                            your
                                                                            personal
                                                                            information
                                                                            by
                                                                            your
                                                                            third-party
                                                                            social
                                                                            media
                                                                            provider.
                                                                            We
                                                                            recommend
                                                                            that
                                                                            you
                                                                            review
                                                                            their
                                                                            privacy
                                                                            notice
                                                                            to
                                                                            understand
                                                                            how
                                                                            they
                                                                            collect,
                                                                            use,
                                                                            and
                                                                            share
                                                                            your
                                                                            personal
                                                                            information,
                                                                            and
                                                                            how
                                                                            you
                                                                            can
                                                                            set
                                                                            your
                                                                            privacy
                                                                            preferences
                                                                            on
                                                                            their
                                                                            sites
                                                                            and
                                                                            apps.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              7.
                                                                              HOW
                                                                              LONG
                                                                              DO
                                                                              WE
                                                                              KEEP
                                                                              YOUR
                                                                              INFORMATION?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              <em>
                                                                                In
                                                                                Short:&nbsp;
                                                                              </em>
                                                                            </strong>
                                                                            <em>
                                                                              We
                                                                              keep
                                                                              your
                                                                              information
                                                                              for
                                                                              as
                                                                              long
                                                                              as
                                                                              necessary
                                                                              to&nbsp;fulfill&nbsp;the
                                                                              purposes
                                                                              outlined
                                                                              in
                                                                              this
                                                                              privacy
                                                                              notice
                                                                              unless
                                                                              otherwise
                                                                              required
                                                                              by
                                                                              law.
                                                                            </em>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            will
                                                                            only
                                                                            keep
                                                                            your
                                                                            personal
                                                                            information
                                                                            for
                                                                            as
                                                                            long
                                                                            as
                                                                            it
                                                                            is
                                                                            necessary
                                                                            for
                                                                            the
                                                                            purposes
                                                                            set
                                                                            out
                                                                            in
                                                                            this
                                                                            privacy
                                                                            notice,
                                                                            unless
                                                                            a
                                                                            longer
                                                                            retention
                                                                            period
                                                                            is
                                                                            required
                                                                            or
                                                                            permitted
                                                                            by
                                                                            law
                                                                            (such
                                                                            as
                                                                            tax,
                                                                            accounting,
                                                                            or
                                                                            other
                                                                            legal
                                                                            requirements).
                                                                            No
                                                                            purpose
                                                                            in
                                                                            this
                                                                            notice
                                                                            will
                                                                            require
                                                                            us
                                                                            keeping
                                                                            your
                                                                            personal
                                                                            information
                                                                            for
                                                                            longer
                                                                            than&nbsp;the
                                                                            period
                                                                            of
                                                                            time
                                                                            in
                                                                            which
                                                                            users
                                                                            have
                                                                            an
                                                                            account
                                                                            with
                                                                            us.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            When
                                                                            we
                                                                            have
                                                                            no
                                                                            ongoing
                                                                            legitimate
                                                                            business
                                                                            need
                                                                            to
                                                                            process
                                                                            your
                                                                            personal
                                                                            information,
                                                                            we
                                                                            will
                                                                            either
                                                                            delete
                                                                            or&nbsp;anonymize&nbsp;such
                                                                            information,
                                                                            or,
                                                                            if
                                                                            this
                                                                            is
                                                                            not
                                                                            possible
                                                                            (for
                                                                            example,
                                                                            because
                                                                            your
                                                                            personal
                                                                            information
                                                                            has
                                                                            been
                                                                            stored
                                                                            in
                                                                            backup
                                                                            archives),
                                                                            then
                                                                            we
                                                                            will
                                                                            securely
                                                                            store
                                                                            your
                                                                            personal
                                                                            information
                                                                            and
                                                                            isolate
                                                                            it
                                                                            from
                                                                            any
                                                                            further
                                                                            processing
                                                                            until
                                                                            deletion
                                                                            is
                                                                            possible.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              8.
                                                                              HOW
                                                                              DO
                                                                              WE
                                                                              KEEP
                                                                              YOUR
                                                                              INFORMATION
                                                                              SAFE?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              <em>
                                                                                In
                                                                                Short:&nbsp;
                                                                              </em>
                                                                            </strong>
                                                                            <em>
                                                                              We
                                                                              aim
                                                                              to
                                                                              protect
                                                                              your
                                                                              personal
                                                                              information
                                                                              through
                                                                              a
                                                                              system
                                                                              of&nbsp;organizational&nbsp;and
                                                                              technical
                                                                              security
                                                                              measures.
                                                                            </em>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            have
                                                                            implemented
                                                                            appropriate
                                                                            and
                                                                            reasonable
                                                                            technical
                                                                            and&nbsp;organizational&nbsp;security
                                                                            measures
                                                                            designed
                                                                            to
                                                                            protect
                                                                            the
                                                                            security
                                                                            of
                                                                            any
                                                                            personal
                                                                            information
                                                                            we
                                                                            process.
                                                                            However,
                                                                            despite
                                                                            our
                                                                            safeguards
                                                                            and
                                                                            efforts
                                                                            to
                                                                            secure
                                                                            your
                                                                            information,
                                                                            no
                                                                            electronic
                                                                            transmission
                                                                            over
                                                                            the
                                                                            Internet
                                                                            or
                                                                            information
                                                                            storage
                                                                            technology
                                                                            can
                                                                            be
                                                                            guaranteed
                                                                            to
                                                                            be
                                                                            100%
                                                                            secure,
                                                                            so
                                                                            we
                                                                            cannot
                                                                            promise
                                                                            or
                                                                            guarantee
                                                                            that
                                                                            hackers,
                                                                            cybercriminals,
                                                                            or
                                                                            other&nbsp;unauthorized&nbsp;third
                                                                            parties
                                                                            will
                                                                            not
                                                                            be
                                                                            able
                                                                            to
                                                                            defeat
                                                                            our
                                                                            security
                                                                            and
                                                                            improperly
                                                                            collect,
                                                                            access,
                                                                            steal,
                                                                            or
                                                                            modify
                                                                            your
                                                                            information.
                                                                            Although
                                                                            we
                                                                            will
                                                                            do
                                                                            our
                                                                            best
                                                                            to
                                                                            protect
                                                                            your
                                                                            personal
                                                                            information,
                                                                            transmission
                                                                            of
                                                                            personal
                                                                            information
                                                                            to
                                                                            and
                                                                            from
                                                                            our
                                                                            Services
                                                                            is
                                                                            at
                                                                            your
                                                                            own
                                                                            risk.
                                                                            You
                                                                            should
                                                                            only
                                                                            access
                                                                            the
                                                                            Services
                                                                            within
                                                                            a
                                                                            secure
                                                                            environment.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              9.
                                                                              DO
                                                                              WE
                                                                              COLLECT
                                                                              INFORMATION
                                                                              FROM
                                                                              MINORS?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              <em>
                                                                                In
                                                                                Short:
                                                                              </em>
                                                                            </strong>
                                                                            <em>
                                                                              &nbsp;We
                                                                              do
                                                                              not
                                                                              knowingly
                                                                              collect
                                                                              data
                                                                              from
                                                                              or
                                                                              market
                                                                              to
                                                                              children
                                                                              under
                                                                              18
                                                                              years
                                                                              of
                                                                              age.
                                                                            </em>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            do
                                                                            not
                                                                            knowingly
                                                                            solicit
                                                                            data
                                                                            from
                                                                            or
                                                                            market
                                                                            to
                                                                            children
                                                                            under
                                                                            18
                                                                            years
                                                                            of
                                                                            age.
                                                                            By
                                                                            using
                                                                            the
                                                                            Services,
                                                                            you
                                                                            represent
                                                                            that
                                                                            you
                                                                            are
                                                                            at
                                                                            least
                                                                            18
                                                                            or
                                                                            that
                                                                            you
                                                                            are
                                                                            the
                                                                            parent
                                                                            or
                                                                            guardian
                                                                            of
                                                                            such
                                                                            a
                                                                            minor
                                                                            and
                                                                            consent
                                                                            to
                                                                            such
                                                                            minor
                                                                            dependent&rsquo;s
                                                                            use
                                                                            of
                                                                            the
                                                                            Services.
                                                                            If
                                                                            we
                                                                            learn
                                                                            that
                                                                            personal
                                                                            information
                                                                            from
                                                                            users
                                                                            less
                                                                            than
                                                                            18
                                                                            years
                                                                            of
                                                                            age
                                                                            has
                                                                            been
                                                                            collected,
                                                                            we
                                                                            will
                                                                            deactivate
                                                                            the
                                                                            account
                                                                            and
                                                                            take
                                                                            reasonable
                                                                            measures
                                                                            to
                                                                            promptly
                                                                            delete
                                                                            such
                                                                            data
                                                                            from
                                                                            our
                                                                            records.
                                                                            If
                                                                            you
                                                                            become
                                                                            aware
                                                                            of
                                                                            any
                                                                            data
                                                                            we
                                                                            may
                                                                            have
                                                                            collected
                                                                            from
                                                                            children
                                                                            under
                                                                            age
                                                                            18,
                                                                            please
                                                                            contact
                                                                            us
                                                                            at&nbsp;
                                                                            <span data-custom-class="body_text">
                                                                              support@natureinfluencer.com
                                                                            </span>
                                                                            .
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              10.
                                                                              WHAT
                                                                              ARE
                                                                              YOUR
                                                                              PRIVACY
                                                                              RIGHTS?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              <em>
                                                                                In
                                                                                Short:
                                                                              </em>
                                                                            </strong>
                                                                            <em>
                                                                              &nbsp;In
                                                                              some
                                                                              regions,
                                                                              such
                                                                              as&nbsp;the
                                                                              European
                                                                              Economic
                                                                              Area
                                                                              (EEA),
                                                                              United
                                                                              Kingdom
                                                                              (UK),
                                                                              and
                                                                              Canada,
                                                                              you
                                                                              have
                                                                              rights
                                                                              that
                                                                              allow
                                                                              you
                                                                              greater
                                                                              access
                                                                              to
                                                                              and
                                                                              control
                                                                              over
                                                                              your
                                                                              personal
                                                                              information.&nbsp;You
                                                                              may
                                                                              review,
                                                                              change,
                                                                              or
                                                                              terminate
                                                                              your
                                                                              account
                                                                              at
                                                                              any
                                                                              time.
                                                                            </em>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            In
                                                                            some
                                                                            regions
                                                                            (like&nbsp;the
                                                                            EEA,
                                                                            UK,
                                                                            and
                                                                            Canada),
                                                                            you
                                                                            have
                                                                            certain
                                                                            rights
                                                                            under
                                                                            applicable
                                                                            data
                                                                            protection
                                                                            laws.
                                                                            These
                                                                            may
                                                                            include
                                                                            the
                                                                            right
                                                                            (i)
                                                                            to
                                                                            request
                                                                            access
                                                                            and
                                                                            obtain
                                                                            a
                                                                            copy
                                                                            of
                                                                            your
                                                                            personal
                                                                            information,
                                                                            (ii)
                                                                            to
                                                                            request
                                                                            rectification
                                                                            or
                                                                            erasure;
                                                                            (iii)
                                                                            to
                                                                            restrict
                                                                            the
                                                                            processing
                                                                            of
                                                                            your
                                                                            personal
                                                                            information;
                                                                            and
                                                                            (iv)
                                                                            if
                                                                            applicable,
                                                                            to
                                                                            data
                                                                            portability.
                                                                            In
                                                                            certain
                                                                            circumstances,
                                                                            you
                                                                            may
                                                                            also
                                                                            have
                                                                            the
                                                                            right
                                                                            to
                                                                            object
                                                                            to
                                                                            the
                                                                            processing
                                                                            of
                                                                            your
                                                                            personal
                                                                            information.
                                                                            You
                                                                            can
                                                                            make
                                                                            such
                                                                            a
                                                                            request
                                                                            by
                                                                            contacting
                                                                            us
                                                                            by
                                                                            using
                                                                            the
                                                                            contact
                                                                            details
                                                                            provided
                                                                            in
                                                                            the
                                                                            section&nbsp;"
                                                                          </span>
                                                                          <a
                                                                            href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#contact"
                                                                            data-custom-class="link"
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              HOW
                                                                              CAN
                                                                              YOU
                                                                              CONTACT
                                                                              US
                                                                              ABOUT
                                                                              THIS
                                                                              NOTICE?
                                                                            </span>
                                                                          </a>
                                                                          <span data-custom-class="body_text">
                                                                            "&nbsp;below.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            will
                                                                            consider
                                                                            and
                                                                            act
                                                                            upon
                                                                            any
                                                                            request
                                                                            in
                                                                            accordance
                                                                            with
                                                                            applicable
                                                                            data
                                                                            protection
                                                                            laws.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            If
                                                                            you
                                                                            are
                                                                            located
                                                                            in
                                                                            the
                                                                            EEA
                                                                            or
                                                                            UK
                                                                            and
                                                                            you
                                                                            believe
                                                                            we
                                                                            are
                                                                            unlawfully
                                                                            processing
                                                                            your
                                                                            personal
                                                                            information,
                                                                            you
                                                                            also
                                                                            have
                                                                            the
                                                                            right
                                                                            to
                                                                            complain
                                                                            to
                                                                            your
                                                                            local
                                                                            data
                                                                            protection
                                                                            supervisory
                                                                            authority.
                                                                            You
                                                                            can
                                                                            find
                                                                            their
                                                                            contact
                                                                            details
                                                                            here:&nbsp;
                                                                            <span data-custom-class="body_text">
                                                                              <span data-custom-class="body_text">
                                                                                <a
                                                                                  href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                  target="_blank"
                                                                                  rel="noopener noreferrer"
                                                                                  data-custom-class="link"
                                                                                >
                                                                                  https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                                </a>
                                                                              </span>
                                                                            </span>
                                                                            .
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            If
                                                                            you
                                                                            are
                                                                            located
                                                                            in
                                                                            Switzerland,
                                                                            the
                                                                            contact
                                                                            details
                                                                            for
                                                                            the
                                                                            data
                                                                            protection
                                                                            authorities
                                                                            are
                                                                            available
                                                                            here:&nbsp;
                                                                            <span data-custom-class="body_text">
                                                                              <span data-custom-class="body_text">
                                                                                <a
                                                                                  href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                  target="_blank"
                                                                                  rel="noopener noreferrer"
                                                                                  data-custom-class="link"
                                                                                >
                                                                                  https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                                </a>
                                                                              </span>
                                                                            </span>
                                                                            .
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              <u>
                                                                                Withdrawing
                                                                                your
                                                                                consent:
                                                                              </u>
                                                                            </strong>
                                                                            &nbsp;If
                                                                            we
                                                                            are
                                                                            relying
                                                                            on
                                                                            your
                                                                            consent
                                                                            to
                                                                            process
                                                                            your
                                                                            personal
                                                                            information,&nbsp;which
                                                                            may
                                                                            be
                                                                            express
                                                                            and/or
                                                                            implied
                                                                            consent
                                                                            depending
                                                                            on
                                                                            the
                                                                            applicable
                                                                            law,&nbsp;you
                                                                            have
                                                                            the
                                                                            right
                                                                            to
                                                                            withdraw
                                                                            your
                                                                            consent
                                                                            at
                                                                            any
                                                                            time.
                                                                            You
                                                                            can
                                                                            withdraw
                                                                            your
                                                                            consent
                                                                            at
                                                                            any
                                                                            time
                                                                            by
                                                                            contacting
                                                                            us
                                                                            by
                                                                            using
                                                                            the
                                                                            contact
                                                                            details
                                                                            provided
                                                                            in
                                                                            the
                                                                            section&nbsp;"
                                                                          </span>
                                                                          <a
                                                                            href="https://app.termly.io/document/privacy-policy/0b3949a3-571f-4dab-b0f9-5defab987194#contact"
                                                                            data-custom-class="link"
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              HOW
                                                                              CAN
                                                                              YOU
                                                                              CONTACT
                                                                              US
                                                                              ABOUT
                                                                              THIS
                                                                              NOTICE?
                                                                            </span>
                                                                          </a>
                                                                          <span data-custom-class="body_text">
                                                                            "&nbsp;below.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            However,
                                                                            please
                                                                            note
                                                                            that
                                                                            this
                                                                            will
                                                                            not
                                                                            affect
                                                                            the
                                                                            lawfulness
                                                                            of
                                                                            the
                                                                            processing
                                                                            before
                                                                            its
                                                                            withdrawal,
                                                                            nor&nbsp;when
                                                                            applicable
                                                                            law
                                                                            allows,&nbsp;will
                                                                            it
                                                                            affect
                                                                            the
                                                                            processing
                                                                            of
                                                                            your
                                                                            personal
                                                                            information
                                                                            conducted
                                                                            in
                                                                            reliance
                                                                            on
                                                                            lawful
                                                                            processing
                                                                            grounds
                                                                            other
                                                                            than
                                                                            consent.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="heading_2">
                                                                            <strong>
                                                                              Account
                                                                              Information
                                                                            </strong>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            If
                                                                            you
                                                                            would
                                                                            at
                                                                            any
                                                                            time
                                                                            like
                                                                            to
                                                                            review
                                                                            or
                                                                            change
                                                                            the
                                                                            information
                                                                            in
                                                                            your
                                                                            account
                                                                            or
                                                                            terminate
                                                                            your
                                                                            account,
                                                                            you
                                                                            can:
                                                                          </span>
                                                                        </div>
                                                                        <ul>
                                                                          <li>
                                                                            <span data-custom-class="body_text">
                                                                              Contact
                                                                              us
                                                                              using
                                                                              the
                                                                              contact
                                                                              information
                                                                              provided.
                                                                            </span>
                                                                          </li>
                                                                        </ul>
                                                                        <ul>
                                                                          <li>
                                                                            <span data-custom-class="body_text">
                                                                              For
                                                                              data
                                                                              deletion
                                                                              requests,
                                                                              please
                                                                              send
                                                                              us
                                                                              an
                                                                              email
                                                                              to
                                                                              support@natureinfluencer.com.
                                                                              Make
                                                                              sure
                                                                              to
                                                                              include
                                                                              your
                                                                              Instagram
                                                                              User
                                                                              ID.
                                                                              You
                                                                              can
                                                                              find
                                                                              your
                                                                              Instagram
                                                                              User
                                                                              ID
                                                                              by
                                                                              opening
                                                                              the
                                                                              Instagram
                                                                              app
                                                                              and
                                                                              going
                                                                              to:
                                                                              Profile
                                                                              -&gt;
                                                                              Settings
                                                                              -&gt;
                                                                              Security
                                                                              -&gt;
                                                                              Apps
                                                                              and
                                                                              websites
                                                                              -&gt;
                                                                              Nature
                                                                              Influencer
                                                                              (View
                                                                              and
                                                                              Edit)
                                                                              -&gt;
                                                                              User
                                                                              ID.
                                                                            </span>
                                                                          </li>
                                                                        </ul>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            Upon
                                                                            your
                                                                            request
                                                                            to
                                                                            terminate
                                                                            your
                                                                            account,
                                                                            we
                                                                            will
                                                                            deactivate
                                                                            or
                                                                            delete
                                                                            your
                                                                            account
                                                                            and
                                                                            information
                                                                            from
                                                                            our
                                                                            active
                                                                            databases.
                                                                            However,
                                                                            we
                                                                            may
                                                                            retain
                                                                            some
                                                                            information
                                                                            in
                                                                            our
                                                                            files
                                                                            to
                                                                            prevent
                                                                            fraud,
                                                                            troubleshoot
                                                                            problems,
                                                                            assist
                                                                            with
                                                                            any
                                                                            investigations,
                                                                            enforce
                                                                            our
                                                                            legal
                                                                            terms
                                                                            and/or
                                                                            comply
                                                                            with
                                                                            applicable
                                                                            legal
                                                                            requirements.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              <u>
                                                                                Cookies
                                                                                and
                                                                                similar
                                                                                technologies:
                                                                              </u>
                                                                            </strong>
                                                                            &nbsp;Most
                                                                            Web
                                                                            browsers
                                                                            are
                                                                            set
                                                                            to
                                                                            accept
                                                                            cookies
                                                                            by
                                                                            default.
                                                                            If
                                                                            you
                                                                            prefer,
                                                                            you
                                                                            can
                                                                            usually
                                                                            choose
                                                                            to
                                                                            set
                                                                            your
                                                                            browser
                                                                            to
                                                                            remove
                                                                            cookies
                                                                            and
                                                                            to
                                                                            reject
                                                                            cookies.
                                                                            If
                                                                            you
                                                                            choose
                                                                            to
                                                                            remove
                                                                            cookies
                                                                            or
                                                                            reject
                                                                            cookies,
                                                                            this
                                                                            could
                                                                            affect
                                                                            certain
                                                                            features
                                                                            or
                                                                            services
                                                                            of
                                                                            our
                                                                            Services.
                                                                            To
                                                                            opt
                                                                            out
                                                                            of
                                                                            interest-based
                                                                            advertising
                                                                            by
                                                                            advertisers
                                                                            on
                                                                            our
                                                                            Services
                                                                            visit&nbsp;
                                                                            <span data-custom-class="body_text">
                                                                              <a
                                                                                href="http://www.aboutads.info/choices/"
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                data-custom-class="link"
                                                                              >
                                                                                http://www.aboutads.info/choices/
                                                                              </a>
                                                                            </span>
                                                                            .
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            If
                                                                            you
                                                                            have
                                                                            questions
                                                                            or
                                                                            comments
                                                                            about
                                                                            your
                                                                            privacy
                                                                            rights,
                                                                            you
                                                                            may
                                                                            email
                                                                            us
                                                                            at&nbsp;support@natureinfluencer.com.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              11.
                                                                              CONTROLS
                                                                              FOR
                                                                              DO-NOT-TRACK
                                                                              FEATURES
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            Most
                                                                            web
                                                                            browsers
                                                                            and
                                                                            some
                                                                            mobile
                                                                            operating
                                                                            systems
                                                                            and
                                                                            mobile
                                                                            applications
                                                                            include
                                                                            a
                                                                            Do-Not-Track
                                                                            ("DNT")
                                                                            feature
                                                                            or
                                                                            setting
                                                                            you
                                                                            can
                                                                            activate
                                                                            to
                                                                            signal
                                                                            your
                                                                            privacy
                                                                            preference
                                                                            not
                                                                            to
                                                                            have
                                                                            data
                                                                            about
                                                                            your
                                                                            online
                                                                            browsing
                                                                            activities
                                                                            monitored
                                                                            and
                                                                            collected.
                                                                            At
                                                                            this
                                                                            stage
                                                                            no
                                                                            uniform
                                                                            technology
                                                                            standard
                                                                            for&nbsp;recognizing&nbsp;and
                                                                            implementing
                                                                            DNT
                                                                            signals
                                                                            has
                                                                            been&nbsp;finalized.
                                                                            As
                                                                            such,
                                                                            we
                                                                            do
                                                                            not
                                                                            currently
                                                                            respond
                                                                            to
                                                                            DNT
                                                                            browser
                                                                            signals
                                                                            or
                                                                            any
                                                                            other
                                                                            mechanism
                                                                            that
                                                                            automatically
                                                                            communicates
                                                                            your
                                                                            choice
                                                                            not
                                                                            to
                                                                            be
                                                                            tracked
                                                                            online.
                                                                            If a
                                                                            standard
                                                                            for
                                                                            online
                                                                            tracking
                                                                            is
                                                                            adopted
                                                                            that
                                                                            we
                                                                            must
                                                                            follow
                                                                            in
                                                                            the
                                                                            future,
                                                                            we
                                                                            will
                                                                            inform
                                                                            you
                                                                            about
                                                                            that
                                                                            practice
                                                                            in a
                                                                            revised
                                                                            version
                                                                            of
                                                                            this
                                                                            privacy
                                                                            notice.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              12.
                                                                              DO
                                                                              CALIFORNIA
                                                                              RESIDENTS
                                                                              HAVE
                                                                              SPECIFIC
                                                                              PRIVACY
                                                                              RIGHTS?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              <em>
                                                                                In
                                                                                Short:&nbsp;
                                                                              </em>
                                                                            </strong>
                                                                            <em>
                                                                              Yes,
                                                                              if
                                                                              you
                                                                              are
                                                                              a
                                                                              resident
                                                                              of
                                                                              California,
                                                                              you
                                                                              are
                                                                              granted
                                                                              specific
                                                                              rights
                                                                              regarding
                                                                              access
                                                                              to
                                                                              your
                                                                              personal
                                                                              information.
                                                                            </em>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            California
                                                                            Civil
                                                                            Code
                                                                            Section
                                                                            1798.83,
                                                                            also
                                                                            known
                                                                            as
                                                                            the&nbsp;"Shine
                                                                            The
                                                                            Light"&nbsp;law,
                                                                            permits
                                                                            our
                                                                            users
                                                                            who
                                                                            are
                                                                            California
                                                                            residents
                                                                            to
                                                                            request
                                                                            and
                                                                            obtain
                                                                            from
                                                                            us,
                                                                            once
                                                                            a
                                                                            year
                                                                            and
                                                                            free
                                                                            of
                                                                            charge,
                                                                            information
                                                                            about
                                                                            categories
                                                                            of
                                                                            personal
                                                                            information
                                                                            (if
                                                                            any)
                                                                            we
                                                                            disclosed
                                                                            to
                                                                            third
                                                                            parties
                                                                            for
                                                                            direct
                                                                            marketing
                                                                            purposes
                                                                            and
                                                                            the
                                                                            names
                                                                            and
                                                                            addresses
                                                                            of
                                                                            all
                                                                            third
                                                                            parties
                                                                            with
                                                                            which
                                                                            we
                                                                            shared
                                                                            personal
                                                                            information
                                                                            in
                                                                            the
                                                                            immediately
                                                                            preceding
                                                                            calendar
                                                                            year.
                                                                            If
                                                                            you
                                                                            are
                                                                            a
                                                                            California
                                                                            resident
                                                                            and
                                                                            would
                                                                            like
                                                                            to
                                                                            make
                                                                            such
                                                                            a
                                                                            request,
                                                                            please
                                                                            submit
                                                                            your
                                                                            request
                                                                            in
                                                                            writing
                                                                            to
                                                                            us
                                                                            using
                                                                            the
                                                                            contact
                                                                            information
                                                                            provided
                                                                            below.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            If
                                                                            you
                                                                            are
                                                                            under
                                                                            18
                                                                            years
                                                                            of
                                                                            age,
                                                                            reside
                                                                            in
                                                                            California,
                                                                            and
                                                                            have
                                                                            a
                                                                            registered
                                                                            account
                                                                            with
                                                                            Services,
                                                                            you
                                                                            have
                                                                            the
                                                                            right
                                                                            to
                                                                            request
                                                                            removal
                                                                            of
                                                                            unwanted
                                                                            data
                                                                            that
                                                                            you
                                                                            publicly
                                                                            post
                                                                            on
                                                                            the
                                                                            Services.
                                                                            To
                                                                            request
                                                                            removal
                                                                            of
                                                                            such
                                                                            data,
                                                                            please
                                                                            contact
                                                                            us
                                                                            using
                                                                            the
                                                                            contact
                                                                            information
                                                                            provided
                                                                            below
                                                                            and
                                                                            include
                                                                            the
                                                                            email
                                                                            address
                                                                            associated
                                                                            with
                                                                            your
                                                                            account
                                                                            and
                                                                            a
                                                                            statement
                                                                            that
                                                                            you
                                                                            reside
                                                                            in
                                                                            California.
                                                                            We
                                                                            will
                                                                            make
                                                                            sure
                                                                            the
                                                                            data
                                                                            is
                                                                            not
                                                                            publicly
                                                                            displayed
                                                                            on
                                                                            the
                                                                            Services,
                                                                            but
                                                                            please
                                                                            be
                                                                            aware
                                                                            that
                                                                            the
                                                                            data
                                                                            may
                                                                            not
                                                                            be
                                                                            completely
                                                                            or
                                                                            comprehensively
                                                                            removed
                                                                            from
                                                                            all
                                                                            our
                                                                            systems
                                                                            (e.g.,&nbsp;backups,
                                                                            etc.).
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              13.
                                                                              DO
                                                                              WE
                                                                              MAKE
                                                                              UPDATES
                                                                              TO
                                                                              THIS
                                                                              NOTICE?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <em>
                                                                              <strong>
                                                                                In
                                                                                Short:&nbsp;
                                                                              </strong>
                                                                              Yes,
                                                                              we
                                                                              will
                                                                              update
                                                                              this
                                                                              notice
                                                                              as
                                                                              necessary
                                                                              to
                                                                              stay
                                                                              compliant
                                                                              with
                                                                              relevant
                                                                              laws.
                                                                            </em>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            We
                                                                            may
                                                                            update
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            from
                                                                            time
                                                                            to
                                                                            time.
                                                                            The
                                                                            updated
                                                                            version
                                                                            will
                                                                            be
                                                                            indicated
                                                                            by
                                                                            an
                                                                            updated&nbsp;"Revised"&nbsp;date
                                                                            and
                                                                            the
                                                                            updated
                                                                            version
                                                                            will
                                                                            be
                                                                            effective
                                                                            as
                                                                            soon
                                                                            as
                                                                            it
                                                                            is
                                                                            accessible.
                                                                            If
                                                                            we
                                                                            make
                                                                            material
                                                                            changes
                                                                            to
                                                                            this
                                                                            privacy
                                                                            notice,
                                                                            we
                                                                            may
                                                                            notify
                                                                            you
                                                                            either
                                                                            by
                                                                            prominently
                                                                            posting
                                                                            a
                                                                            notice
                                                                            of
                                                                            such
                                                                            changes
                                                                            or
                                                                            by
                                                                            directly
                                                                            sending
                                                                            you
                                                                            a
                                                                            notification.
                                                                            We
                                                                            encourage
                                                                            you
                                                                            to
                                                                            review
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            frequently
                                                                            to
                                                                            be
                                                                            informed
                                                                            of
                                                                            how
                                                                            we
                                                                            are
                                                                            protecting
                                                                            your
                                                                            information.
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              14.
                                                                              HOW
                                                                              CAN
                                                                              YOU
                                                                              CONTACT
                                                                              US
                                                                              ABOUT
                                                                              THIS
                                                                              NOTICE?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            If
                                                                            you
                                                                            have
                                                                            questions
                                                                            or
                                                                            comments
                                                                            about
                                                                            this
                                                                            notice,
                                                                            you
                                                                            may&nbsp;
                                                                            <span data-custom-class="body_text">
                                                                              email
                                                                              us
                                                                              at&nbsp;support@natureinfluencer.com
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                              &nbsp;or
                                                                              by
                                                                              post
                                                                              to:
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            <span data-custom-class="body_text">
                                                                              Lateral
                                                                              Group
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            110
                                                                            Wall
                                                                            Street
                                                                            New
                                                                            York
                                                                            City,
                                                                            NY
                                                                            10005
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            New
                                                                            York,&nbsp;NY&nbsp;10005
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            United
                                                                            States
                                                                          </span>
                                                                        </div>
                                                                        <div>
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              15.
                                                                              HOW
                                                                              CAN
                                                                              YOU
                                                                              REVIEW,
                                                                              UPDATE,
                                                                              OR
                                                                              DELETE
                                                                              THE
                                                                              DATA
                                                                              WE
                                                                              COLLECT
                                                                              FROM
                                                                              YOU?
                                                                            </span>
                                                                          </strong>
                                                                        </div>
                                                                        <div>
                                                                          <span data-custom-class="body_text">
                                                                            Based
                                                                            on
                                                                            the
                                                                            applicable
                                                                            laws
                                                                            of
                                                                            your
                                                                            country,
                                                                            you
                                                                            may
                                                                            have
                                                                            the
                                                                            right
                                                                            to
                                                                            request
                                                                            access
                                                                            to
                                                                            the
                                                                            personal
                                                                            information
                                                                            we
                                                                            collect
                                                                            from
                                                                            you,
                                                                            change
                                                                            that
                                                                            information,
                                                                            or
                                                                            delete
                                                                            it.
                                                                            To
                                                                            request
                                                                            to
                                                                            review,
                                                                            update,
                                                                            or
                                                                            delete
                                                                            your
                                                                            personal
                                                                            information,
                                                                            please&nbsp;
                                                                          </span>
                                                                          <span data-custom-class="body_text">
                                                                            visit:&nbsp;
                                                                            <a
                                                                              href="https://natureinfluencer.com/privacy"
                                                                              target="_blank"
                                                                              data-custom-class="link"
                                                                            >
                                                                              https://natureinfluencer.com/privacy
                                                                            </a>
                                                                          </span>
                                                                          <span data-custom-class="body_text">
                                                                            .
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="datadeletion" className="p-6 rounded-xl bg-blue-50 mt-10">
              <p className=" font-bold text-xl">Data deletion:</p>
              <p>
                For data deletion requests, please send us an email to{" "}
                <a href="mailto:support@natureinfluencer.com?subject=Data deletion&body:Enter your Instagram username here:">
                  <strong> support@natureinfluencer.com</strong>
                </a>
                . Make sure to include your Instagram User ID and Facebook User
                ID. You can find your Instagram User ID by opening the Instagram
                app and going to: Profile -&gt; Settings -&gt; Security -&gt;
                Apps and websites -&gt; Nature Influencer (View and Edit) -&gt;
                User ID. link .
              </p>
              <p className="mt-2">
                To find your <strong>Facebook User ID:</strong> 1. Tap in the
                top right of Facebook. 2. Scroll down and tap Settings. 3. Tap
                Apps and websites, then tap Logged in with Facebook. 4. Tap on
                the name of the app. 5. Scroll down to learn more. 6. Your user
                ID is in the paragraph below.
              </p>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}
export default PrivacyPage;
