import * as React from 'react'

export default function InstagraConnect() {
  return (
    <>
        <h1 class="text-center text-5xl mb-10">Partner with Nature</h1>
        <h1 class="font-serif text-center text-2xl">
          Connect to your Instagram account:
        </h1>
        <h1 class="font-serif text-center text-2xl">
          to view nature's impact on your likes and calculate your suggested donation:
        </h1>
      {/* This is only for CSS classes for the instagram_connect.html template */}
      <button
        class="px-5 py-3 rounded-full text-white text-xl font-light bg-gradient-to-r from-indigo-700 via-pink-600 to-yellow-300 bg-pink-600 hover:opacity-80 active:opacity-100 transition-all"
        onClick="instagramRedirect()"
      >
        Connect to Instagram
      </button>
    </>
  );
}
